import authService from "../components/api-authorization/AuthorizeService";
import moment from "moment";

export const groupBy = (collection, property) => {
  var i = 0, val, index,
    values = [], result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    //console.log(val,index)
    if (index > -1) {
      result[index].items.push(collection[i]);
    }
    else {
      var item = [];
      item.push(collection[i])

      values.push(val);
      result.push({ items: item, key: val });
    }
  }
  return result;
}

export const passwordCheck = (pwd) => {
  // at least 1 number, 1 lowercase, 1 uppercase letter and 1 symbol
  // at least 8 characters or more
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;"'<>,?/~_+\-=|\\]).{8,}$/;
  return re.test(pwd);
}

export const emailCheck = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|("."))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const findDuplicates = (arr) => {
  let sorted_arr = arr.slice().sort(); 
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}

export const historyCheck = () => {
  var ua = window.navigator.userAgent;
  if ((ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) && ua.indexOf('Mobile Safari') !== -1 && ua.indexOf('Chrome') === -1 && ua.indexOf('Windows Phone') === -1) return false;
  return window.history && 'pushState' in window.history;
}

export const removePhonePrefix = (phone) => {
  var end = phone.indexOf(') ');
  var end2 = phone.indexOf(')');
  //console.log(end,end2, phone.length, phone);
  if (end !== -1)
    return phone.substring(end + 2, phone.length);
  else if (end2 !== -1)
    return phone.substring(end2 + 1, phone.length);
  else return phone;

}

export const postalCodeCheck = (postalCode) => {
  var regex = /^\w{3,9}$/;
  return postalCode.match(regex);
}

export const phoneCheck = (phone) => {
  var regex = /^\d{6,10}$/;
  return phone.match(regex);
}

export const checkIsNum = (num) => {
  var is_num = /^\d{0,}$/;
  return num.match(is_num);
}

export const isEmpty = (obj) => {
  if (Array.isArray(obj)) {
    if (obj.length > 0)
      return false;
  } else {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
  }

  return true;
}

export const tokenHeader = async function () {
  /*let token = localStorage.getItem("tokenHeader");
  if (typeof token !== 'undefined' && token !== null && token !== 'null'){
    return { 'Authorization': `Bearer ${localStorage.getItem("tokenHeader")}` };
  }else {
    token = await authService.getAccessToken();
    localStorage.setItem("tokenHeader", token);
    return { 'Authorization': `Bearer ${token}` };
  }*/
  const token = await authService.getAccessToken();
  return { 'Authorization': `Bearer ${token}` };

}

export const checkLocalStorage = function (key, data, expired, type) {
  let item = JSON.parse(localStorage.getItem(key));
  let now = moment();
  //console.log(data, item);
  if(data) {
    //console.log('save',data);
    localStorage.setItem(key, JSON.stringify({data:data, time:now}));
    return data;
  }else if (typeof item !== 'undefined' && item !== null && item !== 'null'){
    if(expired && type){
      //console.log(now.diff(item.time,type),expired)
      if(now.diff(item.time,type)>expired){
        localStorage.removeItem(key);
        return null;
      }
      else
        return item.data;
    }
  }else{
    return null;
  }
}

export const renewTokenHeader = async function () {
  let token = await authService.getAccessToken();
  localStorage.setItem("tokenHeader", token);
  return { 'Authorization': `Bearer ${token}` };
}

export const stripeErrorsCheck = async function (error) {
  let errors = { title: '', message: '' };
  switch (error.code) {
    case "expired_card":
    case "incorrect_cvc":
    case "processing_error":
    case "incorrect_address":
    case "incorrect_number":
      errors.title = 'stripe_errors:credit_card_error';
      errors.message = 'stripe_errors:credit_card_' + error.code;
      break;
    case "incomplete_zip":
    case "card_declined":
      errors.title = 'stripe_errors:credit_card_error';
      errors.message = 'stripe_errors:' + error.code;
      break;
    case "charge_expired_for_capture":
    case "country_code_invalid":
    case "country_unsupported":
    case "coupon_expired":
    case "email_invalid":
    case "rate_limit":
    case "charge_exceeds_source_limit":
      errors.title = 'stripe_errors:payment_error';
      errors.message = 'stripe_errors:' + error.code;
      break;
    default:
      errors.title = 'stripe_errors:payment_error';
      errors.message = 'stripe_errors:payment_error_please_try_again_later';
      break;
  }
  return errors;
}
export const updatePhonePrefix = (userAddress, data )=>{
  
  const { shippingAddress, shipToBillingAddress} = userAddress;
  let newPhone, { phone } = userAddress, newData;

  if(data.key==='shippingPhonePrefix')
    phone = shippingAddress.phone;

  newPhone = {
    ...phone,
    countryCode: data.countryCode,//HK
    normalizedPhoneNumber: '+'+data.phonePrefix+ phone.phoneNumber, //+85264321325
    prefix: data.phonePrefix
  };

  if(data.key==='billingPhonePrefix' && shipToBillingAddress){
    newData = {
      userAddress: {
        ...userAddress,
        phone: newPhone,
        shippingAddress: {
          ...shippingAddress,
          phone: newPhone
        },shipToBillingAddress: shipToBillingAddress
      },ownUpdate: true
    };
  }
  else if(data.key==='billingPhonePrefix' && !shipToBillingAddress){
    newData = {
      userAddress: {
        ...userAddress,
        phone: newPhone,
      },ownUpdate: true
    };
  }else if(data.key==='shippingPhonePrefix'){
    newData = {
      userAddress: {
        ...userAddress,
        shippingAddress: {
          ...shippingAddress,
          phone: newPhone
        }
      },ownUpdate: true
    };
  }
  //console.log(newData);
  return newData;
}
export const updateAddress = (userAddress, countries, type, data )=>{
  const { billingAddress, shippingAddress} = userAddress;
  let newPhone, { phone, shipToBillingAddress } = userAddress, newData;
  const { key, value } = data;

  if(type==='shipping')
    phone = shippingAddress.phone;

  //if(key=='countryCode'){
  if(key==='phoneCode'){
    const country = countries.find(o => o.code === value);
    newPhone = { ...phone, 
                normalizedPhoneNumber: "+"+country.callPrefix+phone.phoneNumber , //+85264321325
                countryCode: country.code,//HK
                prefix: country.callPrefix //852
              };
  }else if (key === "phone") {
    newPhone = { ...phone, 
                normalizedPhoneNumber: (phone.prefix)?"+"+phone.prefix+value:''+value , //+85264321325
                phoneNumber: value //64321325
              };
  }else{
    newPhone = phone;
  }
  //console.log(key, value, newPhone, type);
  if(type === 'billing'){
  
    if(key==='phone' || key==='phoneCode'){
      if (shipToBillingAddress) {
        newData = {
          userAddress: {
            ...userAddress,
            phone: newPhone,
            shippingAddress: {
              ...shippingAddress,
              address: {
                ...billingAddress
              },
              phone: newPhone
            },
            shipToBillingAddress: true
          },
          ownUpdate: true
        };
      } else {
        newData = {
          userAddress: {
            ...userAddress,
            phone: newPhone
          },
          ownUpdate: true
        };
      }
    }else if(key==='countryCode'){
      if (shipToBillingAddress) {
        newData = {
          userAddress: {
            ...userAddress,
            "phone": newPhone,
            billingAddress:{
              ...billingAddress,
              [key]: value
            },
            shippingAddress: {
              ...shippingAddress,
              address: {
                ...billingAddress,
                [key]: value
              },
              phone: newPhone
            },
            shipToBillingAddress: true
          },
          ownUpdate: true
        };
      } else {
        newData = {
          userAddress: {
            ...userAddress,
            phone: newPhone,
            billingAddress:{
              ...billingAddress,
              [key]: value
            }
          },
          ownUpdate: true
        };
      }
    }else{
      if (shipToBillingAddress) { // copy billing to ship
        newData = {
          userAddress: {
            ...userAddress,
            billingAddress: {
              ...billingAddress,
              [key]: value
            },
            shippingAddress: {
              ...shippingAddress,
              address: {
                ...shippingAddress.address,
                [key]: value
              }
            },
          },
          ownUpdate: true
        };
      } else {
        newData = { 
          userAddress: {
            ...userAddress,
            billingAddress: {
              ...billingAddress,
              [key]: value
            },
            shipToBillingAddress: false
          },
          ownUpdate: true
        }
      }
    }
    
  }else if(type === 'shipping'){
    if(key==='phone' || key==='phoneCode'){
      newData = {
        userAddress: {
          ...userAddress,
          shippingAddress: {
            ...shippingAddress,
            phone: newPhone
          }
        },
        ownUpdate: true
      };
    }else if(key==='name'){
      newData = { 
        userAddress: {
          ...userAddress,
          shippingAddress: {
            ...shippingAddress,
            [key]: value
          }
        },
        ownUpdate: true
      }
    }else if(key==='shipToBillingAddress'){
        newData = {
          userAddress: {
            ...userAddress,
            shippingAddress: {
              ...shippingAddress,
              address: {
                ...billingAddress
              },
              phone: userAddress.phone
            },
            shipToBillingAddress: !shipToBillingAddress
          },
          ownUpdate: true
        };
    }else{
      newData = { 
        userAddress: {
          ...userAddress,
          shippingAddress: {
            ...shippingAddress,
            address: {
              ...shippingAddress.address,
              [key]: value
            }
          }
        },
        ownUpdate: true
      }
    }
  }
  return newData;
}

export const getLanguageCode = lang => {
  if (lang) {
    if (lang.indexOf("zh") > -1) {
      return "zh-CN"
    } else if (lang.indexOf("ja") > -1) {
      return "ja"
    } else {
      return "en"
    }
  }
}

export const CommaFormattedPrice = (amount) => {
  var delimiter = ","; // replace comma if desired
  var a = amount.split('.', 2)
  var d = a[1];
  var i = parseInt(a[0]);
  if (isNaN(i)) { return ''; }
  var minus = '';
  if (i < 0) { minus = '-'; }
  i = Math.abs(i);
  var n = new String(i);
  var a = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) { a.unshift(n); }
  n = a.join(delimiter);
  if (d.length < 1 || d === "00") { amount = n; }
  else { amount = n + '.' + d; }
  amount = minus + amount;
  return amount;
}