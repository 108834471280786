import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { InvalidValidation as useStyles } from "./Styles";
import { RenderHeader, RenderFooter, Typography, Button } from "../UI/Core";

class InvalidValidation extends Component {
  static displayName = InvalidValidation.name;

  render() {
    const { t, classes } = this.props;
    const loginPath = ApplicationPaths.Login;
    const theme = "signup";

    // page data
    const headerData = {
      header: t("errors:invalid_validation"),
      theme: theme
    }

    const footerData = [
      <Button component={Link} to={'/signup'} fullWidth>{t("common:signup")}</Button>,
      <Button color="secondary" component={Link} to={loginPath} fullWidth>{t("common:signin")}</Button>
    ]

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <Container maxWidth="xs" className={classes.root}>
        <RenderHeader {...headerData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <Typography dangerouslySetInnerHTML={{ __html: t("errors:invalid_validation_content") }} variant="body1" component="div" />
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />
      </Container>
    )
  }
}

export default withTranslation(["common", "errors"])(withStyles(useStyles)(InvalidValidation));