import { defaultTheme } from "../../themes/theme";
import ColumnGrid from "../../themes/ColumnGrid";
import { Block } from "@material-ui/icons";

const ShareStyle = theme => {
  const { margin, spacing } = defaultTheme;

  return {
    root: {},
    sectionRoot: {
      margin: theme.spacing(margin, 0)
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    overlay: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 1101,
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    // error: {
    //   marginTop: theme.spacing(2),
    //   marginBottom: theme.spacing(0)
    // },
    remarks: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1.75)
    },
    typography: {
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(1)
    }
  }
}

export const Signup = theme => {
  const { margin, spacing } = defaultTheme;
  return ({
  ...ShareStyle(theme),
  brandSectionRoot: {
    width: "100%",
    margin: "1.5rem 0 0 0"
  },
  brandGridContainer: {
    // ...ColumnGrid({ theme: theme, argument: spacing }),
    marginTop: "0.5rem",
    columnGap: "1rem",
    rowGap: "0.5rem"
  },
  item: {
    padding: 0,
    "& a": {
      display: "block"
    }
  },
  logo: {
    display: "block"
    // width: "100%",
    // maxWidth: 130
  },
  aiLogo: {
    width: 150
  },
  aiLogoF: {
    width: 150*1.8
  },
  capLogo: {
    width: 120
  },
  capLogoF: {
    width: 120*1.8
  },
  faLogo: {
    width: 133
  },
  faLogoF: {
    width: 133*1.8
  },
  ctLogo: {
    marginTop: 4,
    width: 210
  },
  ctLogoF: {
    width: 210*1.8
  }
})}

export const SignupForm = theme => ({
  ...ShareStyle(theme),
  sectionHeader: {
    textAlign: "center"
  },
  textArea: {
    fontSize: theme.typography.htmlFontSize,
    color: theme.palette.text.primary,
    maxHeight: "75vh",
    padding: theme.spacing(2.3125, 1.75),
    // margin: theme.spacing(2, 0, 1),
    border: `solid 1px ${theme.palette.border.primary}`,
    borderRadius: 4,
    overflow: "auto",
    "& p": {
      lineHeight: 1.5,
    },
    "& > :first-child": {
      marginTop: 0
    },
    "& > :last-child": {
      marginBottom: 0
    }
  },
  switch: {
    alignItems: "stretch",
    marginBottom: '10px'
  },
  newsletterHeader: {
    fontWeight: 'bold',
    lineHeight: 1.25
  }
})

export const EmailValidation = theme => ({
  ...ShareStyle(theme)
})

export const InvalidValidation = theme => ({
  ...ShareStyle(theme)
})

export const InvalidEmail = theme => ({
  ...ShareStyle(theme)
})

export const ForgotPassword = theme => ({
  ...ShareStyle(theme)
})

export const SendResetPasswordEmail = theme => ({
  ...ShareStyle(theme)
})

export const ResetPassword = theme => ({
  ...ShareStyle(theme)
})

export const VerifyWorkEmail = theme => ({
  ...ShareStyle(theme)
})