import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Container, Box, Stepper, Step, StepLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import Typography from "../UI/CoreTypography";
import { Steps as useStyles } from "./Styles";

class Steps extends Component {
  static displayName = Steps.name;
  constructor(props) {
    super(props);
    this.state = {
      currStep: this.props.currStep,
    }
  }
  componentDidMount() {

  }
  static getDerivedStateFromProps(props, state) {
    if (props.currStep !== state.currStep) {
      return {
        currStep: props.currStep,
      };
    }
    return null;
  }
  render() {
    const { t, classes } = this.props;
    const { currStep } = this.state;

    const steps = [
      {
        label: t("payment:select_subscription"),
        url: "/subscription/subscription"
      },
      {
        label: t("payment:billing_and_shipping"),
        url: "/subscription/shipping-billing"
      },
      {
        label: t("payment:select_payment_method"),
        url: "/subscription/select-payment"
      },
      {
        label: t("payment:check_order"),
        url: "/subscription/checkout"
      },
      {
        label: t("payment:payment_result"),
        url: "/subscription/checkout"
      }
    ]
    let activeStep = 0;
    switch (currStep) {
      case "subscription":
        activeStep = 0;
        break;
      case "shipping-billing":
        activeStep = 1;
        break;
      case "select-payment":
        activeStep = 2;
        break;
      case "checkout":
        activeStep = 3;
        break;
      case "payment-result":
        activeStep = 4;
        break;
      default:
        activeStep = 0;
        break;
    }

    return (
      <Container maxWidth="md" disableGutters component="section" className={classes.root}>
        <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel className={classes.stepper}>
          {steps.map((item, key) => (
            <Step key={key} className={classes.step}>
              <StepLabel StepIconProps={{ classes: { root: classes.iconRoot, active: classes.iconActive } }} classes={{ label: classes.label, active: classes.active, completed: classes.completed }}>
                {activeStep > key ? <Box component="a" href={item.url}>{item.label}</Box>
                  : <Fragment>{item.label}</Fragment>
                }
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* <Grid container direction="column" wrap="nowrap" className={classes.grid}>
          <Grid item className={(currStep === "subscription") ? classes.highlight : ""}>
            <Typography variant="body1" gutterBottom={false} className={classes.text}>{t("payment:select_subscription")}</Typography>
          </Grid>

          <Grid item className={(currStep === "shipping-billing") ? classes.highlight : ""}>
            <Typography variant="body1" gutterBottom={false} className={classes.text}>{t("payment:shipping_billing")}</Typography>
          </Grid>

          <Grid item className={(currStep === "select-payment") ? classes.highlight : ""}>
            <Typography variant="body1" gutterBottom={false} className={classes.text}>{t("payment:select_payment_method")}</Typography>
          </Grid>

          <Grid item className={(currStep === "checkout") ? classes.highlight : ""}>
            <Typography variant="body1" gutterBottom={false} className={classes.text}>{t("payment:check_order")}</Typography>
          </Grid>
        </Grid> */}
      </Container>
    )
  }
}

export default withTranslation(["payment"])(withStyles(useStyles)(Steps));