import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { JoinSubscriptions as useStyles } from "./Styles";
import { Typography, IconLabelButton } from '../UI/Core';
import { Add as AddIcon } from '@material-ui/icons';


export default function JoinSubscriptions(props) {
  const { t } = useTranslation();
  const classes = makeStyles(useStyles)(props);

  return (
    <Card elevation={0} variant="outlined" className={classes.sectionRoot}>
      <CardContent>
        <Typography variant="h5" className={classes.title} gutterBottom>{t("dashboard:join_subscriptions")}</Typography>
        <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: t("dashboard:join_subscriptions_caption")}} />
      </CardContent>
      <CardContent>
        <IconLabelButton color="warning" startIcon={<AddIcon />} component={Link} to="/subscription/subscription">{t("subscription:join_subscription")}</IconLabelButton>
      </CardContent>
    </Card>
  )
}
