import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { GatewayContext } from "../../utils/GatewayContext";
import queryString from "query-string";
import i18n from "../../utils/i18n";
import authService from "../api-authorization/AuthorizeService";
import { ApplicationPaths } from "./../api-authorization/ApiAuthorizationConstants";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Signup as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderFooter, RenderDialog, TextField, Select, ErrorMessage, Button, RenderSectionTitle } from "../UI/Core";
import EmailValidation from "./EmailValidation";
import apiRequest from "../../utils/apiRequest";
import clsx from "clsx";
import links from "../../links/links.json";

class Signup extends Component {
  static displayName = Signup.name;
  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    this.recaptchaRef = React.createRef();

    this.state = {
      title: "",
      email: "",
      firstName: "",
      lastName: "",
      src: params.src || "",
      languageCode: i18n.language,
      RecaptchaResponse: '',
      errors: {},
      message: {},
      success: false,
      disableSubmit: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  };

  async componentDidMount() {
    document.title = (this.props.t("sign_up:sign_up") + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    const token = await authService.getAccessToken();
    if (token)
      window.location.href = "/manage";
  }

  renderFavBrand = () => {
    const { classes } = this.props;
    switch (this.state.src.toLowerCase()) {
      case "ai":
      case "asianinvestor":
        return (
          links.map((item, key) =>
            item?.slug == 'ai' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.classF])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "cap":
      case "campaignasia":
      case "campaignindia":
      case "campaignchina":
      case "campaignjapan":
        return (
          links.map((item, key) =>
            item?.slug == 'cap' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.classF])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "fa":
      case "financeasia":
        return (
          links.map((item, key) =>
            item?.slug == 'fa' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.classF])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "ct":
      case "corporatetreasurer":
        return (
          links.map((item, key) =>
            item?.slug == 'ct' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.classF])} alt={item.name} /></a></Grid> : ''
          )
        )
      default:
    }
  }
  
  renderBrands = () => {
    const { classes } = this.props;
    switch (this.state.src.toLowerCase()) {
      case "ai":
      case "asianinvestor":
        return (
          links.map((item, key) =>
            item?.slug != 'ai' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "cap":
      case "campaignasia":
      case "campaignindia":
      case "campaignchina":
      case "campaignjapan":
        return (
          links.map((item, key) =>
            item?.slug != 'cap' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "fa":
      case "financeasia":
        return (
          links.map((item, key) =>
            item?.slug != 'fa' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid> : ''
          )
        )
      case "ct":
      case "corporatetreasurer":
        return (
          links.map((item, key) =>
            item?.slug != 'ct' ? <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid> : ''
          )
        )
      default:
        return (
          links.map((item, key) =>
            <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid>
          )
        )
    }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  /*handleValidation() {
    const { title, firstName, lastName, email } = this.state;
    let errors = {};
    if (title === "") {
      errors["title"] = "errors:is_required";
    }
    if (firstName === "") {
      errors["firstName"] = "errors:is_required";
    }
    if (lastName === "") {
      errors["lastName"] = "errors:is_required";
    }
    if (email === "") {
      errors["email"] = "errors:is_required";
    } else if (!emailCheck(email)) {
      errors["email"] = "errors:invalid";
    }
    //console.log(errors);
    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      return true;
    } else
      return false;
  }*/

  handleSubmit = async (ev) => {
    ev.preventDefault();
    // if (!this.handleValidation()) {
      try {
        let token = await this.recaptchaRef.current.getValue();
        this.setState({
          disableSubmit: true,
          errors: {},
          message: {},
          RecaptchaResponse: token
        });
        //const signup_resp = await axios.post("/api/v1/userpendings/", {...this.state,RecaptchaResponse:token});
        const signup_resp = await apiRequest.userpendings.submit({...this.state,RecaptchaResponse:token});
        //this.recaptchaRef.current.reset();
        if (signup_resp.status === 200) {
          this.setState({
            success: true,
            title: '',
            firstName: '',
            lastName: '',
            RecaptchaResponse: '',
            errors: {}
          })
          //window.location.href = "/email-validation/" + this.state.email;
        }
      } catch (err) {
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              errors[key] = "errors:" + error.params[key][0];
            });
            console.log(errors);
            this.setState({ errors: errors })
          }
        } else if (err.response.status === 500) {
          this.setState({
            message: { internalServerError: true },
          });
        }
      }
    // }
  }

  handleClose() {
    this.setState({ message: {} })
  }

  renderSignup() {
    const { t, classes } = this.props;
    const { errors, message, title, firstName, lastName, email, disableSubmit, src } = this.state;
    const loginPath = ApplicationPaths.Login;
    const theme = "signup";
    // page data
    const headerData = {
      header: t("sign_up:sign_up"),
      caption: t("sign_up:sign_up_caption"),
      theme: theme
    }

    const dialogData = [
      {
        open: message.emailInvalid,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:email_invalid"),
        children: t("errors:email_registered")
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button fullWidth onClick={this.handleSubmit} disabled={disableSubmit}>{t("common:submit")}</Button>,
      <Button color="secondary" component={Link} to={loginPath} fullWidth>{t("common:signin")}</Button>
    ]

    return (
      <>
        <Container maxWidth="xs" className={classes.root}>
          <Backdrop variant="processing" open={disableSubmit} />
          <RenderHeader {...headerData} />
          <RenderDialog data={dialogData} />

          <Box component="section" className={classes.sectionRoot}>
            <Grid container className={classes.gridContainer}>
              {/* title */}
              <Grid item xs={12}>
                <Select onChange={this.handleInputChange} id="title" name="title" label={t("profile:title") + " *"} value={title} error={Boolean(errors["title"])} disabled={disableSubmit}>
                  <option value=""></option>
                  <GatewayContext.Consumer>
                    {context => (
                      context.titles && Object.keys(context.titles).map(key => (
                        <option key={context.titles[key].slug} value={context.titles[key].slug}>{t("titles:" + context.titles[key].slug)}</option>
                      ))
                    )}
                  </GatewayContext.Consumer>
                </Select>
                {(errors["title"]) && <ErrorMessage>{t("profile:title")}{t(errors["title"])}</ErrorMessage>}
              </Grid>

              {/* first name */}
              <Grid item xs={12}>
                <TextField value={firstName} onChange={this.handleInputChange} id="firstName" name="firstName" label={t("profile:first_name") + " *"} error={Boolean(errors["firstName"])} disabled={disableSubmit} />
                {(errors["firstName"]) && <ErrorMessage>{t("profile:first_name")}{t(errors["firstName"])}</ErrorMessage>}
              </Grid>

              {/* last name */}
              <Grid item xs={12}>
                <TextField value={lastName} onChange={this.handleInputChange} id="lastName" name="lastName" label={t("profile:last_name") + " *"} error={Boolean(errors["lastName"])} disabled={disableSubmit} />
                {(errors["lastName"]) && <ErrorMessage>{t("profile:last_name")}{t(errors["lastName"])}</ErrorMessage>}
              </Grid>

              {/* email */}
              <Grid item xs={12}>
                <TextField value={email} onChange={this.handleInputChange} id="email" name="email" label={t("profile:email") + " *"} error={Boolean(errors["email"])} disabled={disableSubmit} />
                {(errors["email"]) && <ErrorMessage>{t("profile:email")}{t(errors["email"])}</ErrorMessage>}
              </Grid>

              {/* recaptcha */}
              <Grid item xs={12}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                  ref={this.recaptchaRef}
                />
                {(errors["recaptchaResponse"]) && <ErrorMessage>{"Recaptcha"}{t(errors["recaptchaResponse"])}</ErrorMessage>}
              </Grid>
            </Grid>
          </Box>
    

          <RenderFooter data={footerData} theme={theme} fullWidthItems/>

        
        </Container>

        { src && <Container component="section" maxWidth={false} className={classes.brandSectionRoot}>
          <Grid container justify="center" alignItems="center" className={classes.brandGridContainer}>
            {this.renderFavBrand()}
          </Grid>
        </Container>}

        <Container component="section" maxWidth={false} className={classes.brandSectionRoot}>
          <RenderSectionTitle data={src ? t('dashboard:other_brands') : t('dashboard:our_brands')} center />
          <Grid container justify="center" alignItems="center" className={classes.brandGridContainer}>
            {this.renderBrands()}
          </Grid>
        </Container>
      </>
    )
  }

  render() {
    const { success, email } = this.state;

    if (success) {
      return <EmailValidation email={email} />;
    } else {
      return this.renderSignup();
    }
  }
}

export default withTranslation(["common", "profile", "titles", "sign_up", "errors"])(withStyles(useStyles)(Signup));