import React from "react";
import PropTypes from 'prop-types';
import { Box, Grid } from "@material-ui/core";
import { RenderSection as useStyles } from "./Styles";

RenderSection.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.string
}

export default function RenderSection(props) {
  const { data } = props;
  const classes = useStyles(props);

  return data ? (
    <Box component="section" className={classes.root}>
      <Grid container direction="column" className={classes.container}>
        {data.map((item, key) => (
          item && (
            <Grid key={key} item xs className={classes.item}>
              {item}
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  ) : null
}