import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Elements, StripeProvider } from "react-stripe-elements";
import { isEmpty} from "../../utils/Utils";
import InjectedAddNewPaymentForm from "../Payment/AddPaymentForm";
import { Container, Grid, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add as AddIcon, Payment as PaymentIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { RenderHeader, RenderFeatureButton, RenderMessage, RenderDialog, Typography, IconLabelButton } from "../UI/Core";
import { Payment as useStyles, StripeElementsOptions } from "./Styles";
import apiRequest from "../../utils/apiRequest";

class Payments extends Component {
  static displayName = Payments.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      disableSubmit: false,
      payments: [],
      paymentPaging: {},
      token: {},
      message: {},
      confirmItem: '',
      selectedItem: '',
      items: 100,
      addNewPayment: (window.location.href.indexOf("add-payment")>-1)?true:false,
      setDefaultPayment: true,
      stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLEKEY
    };
    this.unlisten = null;
    this.getPayment = this.getPayment.bind(this);
    this.handleSetDefaultPayment = this.handleSetDefaultPayment.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleAddNewPayment = this.handleAddNewPayment.bind(this);
    this.handleCancelAddNewPayment = this.handleCancelAddNewPayment.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderPaymentMethod = this.renderPaymentMethod.bind(this);
    this.renderPayment = this.renderPayment.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.unlisten = this.props.history.listen((location, action) => {
      //console.log("on route change",action, location.pathname, location.pathname.indexOf('add-payment'));
      if( ((action==='PUSH' || action==='POP') || (action==='REPLACE')) && location.pathname.indexOf('add-payment')>-1){
        if(this._isMounted)
          this.setState({ addNewPayment: true, message:{} });
      }else if(((action==='PUSH' || action==='POP') || (action==='REPLACE')) && (location.pathname.indexOf('add-payment')===-1)){
        if(this._isMounted)
          this.setState({ addNewPayment: false});
      }
    });
    await this.getPayment();
  }
  componentWillUnmount() {
    this._isMounted = false;
    if(this.unlisten)
      this.unlisten();
  }
  async getPayment() {
    const { items } = this.state;
    try {
      let res = await apiRequest.paymentMethod.list({limit:items});
      if (res.status === 200) {
        this.setState({
          payments: res.data.results, 
          paymentPaging: res.data.paging, 
          setDefaultPayment:(res.data.results.length===0)?true:false
        });
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }

  handleDeleteCard = (item) => () => {
    this.setState({
      message: { deleteCard: true },
      confirmItem: 'deleteCard',
      selectedItem: item
    });
  }

  handleSetDefaultPayment = (item) => () => {
    this.setState({
      message: { setDefaultPayment: true },
      confirmItem: 'defaultPayment',
      selectedItem: item
    });
  }

  handleAddNewPayment() {
    this.props.history.push({
      pathname: "/manage/payment-method/add-payment",
    });
    //this.setState({ addNewPayment: true, message:{} });
  }

  handleCancelAddNewPayment(e) {
    this.props.history.goBack();
    if(e && e.payments)
      this.setState({ addNewPayment: false, payments:e.payments});
    else{
      //setTimeout(()=>_this.getPayment(), 2000);
      this.setState({ addNewPayment: false });
    }
  }

  async handleConfirm(_token) {
    const { confirmItem, selectedItem } = this.state;
    //_token = (_token && _token.Authorization)?_token:token;
    //console.log('handleOnConfirm',_token);
    //console.log(confirmItem,selectedItem);
    //console.log(confirmItem,selectedItem);return;
    try {
      let res, message, _this = this;
      this.setState({ disableSubmit: true });
      if (confirmItem === 'deleteCard')
        res = await apiRequest.paymentMethod.delete({ 'paymentMethodId': selectedItem.id });
      else if (confirmItem === 'defaultPayment')
        res = await apiRequest.paymentMethod.setDefault({ 'defaultPaymentMethodId': selectedItem.id });
/*      await axios.delete('/api/v1/account/payment-methods',
          { headers: _token, data: { 'paymentMethodId': selectedItem.id } });
      else if (confirmItem === 'defaultPayment')
        res = await axios.patch('/api/v1/account/payment-methods',
          { headers: _token, 'defaultPaymentMethodId': selectedItem.id });*/
      // 202 for default payment , 204 for delete
      if (res.status === 202 || res.status === 204) {
        this.setState({disableSubmit: true});
        setTimeout(()=>_this.getPayment(), 2000);
        
        message = (res.status === 202 && { defaultPaymentChanged: true }) || (res.status === 204 && { cardDeleted: true });
        this.setState({
          message: message, disableSubmit: false
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true}, 
          disableSubmit: false
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true}, 
          disableSubmit: false 
        });
      }
    }
  }

  handleClose() {
    this.setState({ message: {} });
  }

  renderPaymentMethod(item) {
    const { t, classes } = this.props;

    const featureButtonData = [
      <IconLabelButton startIcon={<PaymentIcon />} size="small" onClick={this.handleSetDefaultPayment(item)}>{t("payment:set_as_default")}</IconLabelButton>,
      <IconLabelButton color="error" startIcon={<DeleteIcon />} size="small" onClick={this.handleDeleteCard(item)}>{t("common:delete")}</IconLabelButton>
    ]

    return (
      <Box data-cy={`card-${item.last4}`} className={classes.box}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12} md={6} className={classes.titleItem}>
                <Typography variant="h6" className={classes.title}>{item.cardholderName}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.titleItem}>
                <Typography variant="h6" className={classes.title}>{item.brand} **** **** **** {item.last4} </Typography>
              </Grid>
            </Grid>
          </Grid>
          {item.isDefault ?
            <Grid item xs={12} sm={6} md={4} className={classes.valueItem}>{t("payment:default_payment")}</Grid>
            : <Grid item xs={12} sm={6} md={4} className={classes.valueItem}>
              <Grid container component="section" className={classes.featureContainer}>
                {featureButtonData.map((item, key) => (
                  <Grid key={key} item className={classes.featureItem}>
                    {item}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>
    )
  }

  renderPayment() {
    const { t, classes } = this.props;
    const { message, disableSubmit, payments } = this.state;

    // page data
    const headerData = {
      header: t("payment:payment_method"),
      caption: t("payment:payment_method_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.cardDeleted,
        onClose: this.handleClose,
        severity: "success",
        children: t("payment:card_deleted")
      },
      {
        open: message.defaultPaymentChanged,
        onClose: this.handleClose,
        severity: "success",
        children: t("payment:default_payment_changed")
      }
    ]

    const dialogData = [
      {
        open: message.deleteCard,
        onClose: this.handleClose,
        onConfirm: this.handleConfirm,
        title: t("payment:delete_card"),
        children: t("payment:delete_card_content")
      },
      {
        open: message.setDefaultPayment,
        onClose: this.handleClose,
        onConfirm: this.handleConfirm,
        title: t("payment:default_payment"),
        children: t("payment:default_payment_content")
      }
    ]

    const featureButtonData = [
      <IconLabelButton color="third" startIcon={<AddIcon />} onClick={this.handleAddNewPayment} disabled={disableSubmit}>{t("payment:add_new_payment_method")}</IconLabelButton>
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />
        <RenderDialog data={dialogData} />
        <RenderFeatureButton data={featureButtonData} />

        <Box className={classes.sectionRoot}>
          {!isEmpty(payments) ?
            Object.keys(payments).map((key, i) =>
              <Fragment key={i}>
                {this.renderPaymentMethod(payments[key])}
              </Fragment>
            )
            : <Box textAlign="center">{t("payment:no_payment_method")}</Box>
          }
        </Box>
      </Container>
    )
  }

  render() {
    const { i18n } = this.props;
    const { addNewPayment, stripeApiKey, setDefaultPayment } = this.state;
    const locale = (i18n.language === "zh-CN") ? "zh" : i18n.language;
    //console.log(this.state.disableSubmit);
    return (
      <StripeProvider apiKey={stripeApiKey}>
        {!addNewPayment ?
          <Elements>{this.renderPayment()}</Elements>
          : <Elements key={locale} fonts={StripeElementsOptions.fonts} locale={locale}>
            <InjectedAddNewPaymentForm setDefault={setDefaultPayment} handleCancel={this.handleCancelAddNewPayment} />
          </Elements>
        }
      </StripeProvider>
    )
  }
}

export default withTranslation(["common", "errors", "payment"])(withStyles(useStyles)(Payments));