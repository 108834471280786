import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty, checkLocalStorage } from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SubscribeEmails as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, Switch, Button, Typography } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";
import clsx from "clsx";

class SubscribeEmails extends Component {
  static displayName = SubscribeEmails.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      newsletters: {},
      newslettersPaging: {},
      interestIds: [],
      subNewsletter: [],
      subNewsletterPaging: {},
      disableSubmit: false,
      message: "",
      items: 100
    };
    this.maxTrial = 3;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderSubscribeEmail = this.renderSubscribeEmail.bind(this);
  }

  async componentDidMount() {
    //console.log(token);
    this._isMounted = true;
    this.getNewsletterPlan();
    this.getNewsletterSubscription();
  }
  componentWillUnmount() {
    this._isMounted = false;

  }
  async getNewsletterPlan() {
    const { items } = this.state;
    try {
      const res = await apiRequest.newsletter.listPlans({ Status: 'Active', Limit: items });
      if (res.status === 200 && this._isMounted) {
        this.setState({ newsletters: res.data.results, newslettersPaging: res.data.paging });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } });
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } });
      }
    }
  }

  async getNewsletterSubscription() {
    const { items } = this.state;
    try {
      let data = checkLocalStorage('subcribed_emails', null, 5, 'minutes');
      //console.log(data);
      if (!data) {
        const res = await apiRequest.newsletter.list({ Status: 'Active', Limit: items });
        if (res.status === 200 && this._isMounted) {
          let interestIds = res.data.results.filter(o => o.status === 'Active').map(o => o.interestId);
          this.setState({ subNewsletter: res.data.results, interestIds: interestIds, subNewsletterPaging: res.data.paging });
        }
      } else
        this.setState({ subNewsletter: [], interestIds: data, subNewsletterPaging: {} });
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } });
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } });
      }
    }
  }

  handleEmailChange = interestId => {
    let { interestIds } = this.state;
    const index = interestIds.indexOf(interestId);
    if (index > -1) {
      interestIds.splice(index, 1);
    } else {
      interestIds.push(interestId);
    }
    //console.log(interestId, interestIds);
    this.setState({ interestIds: interestIds });
  }

  handleSubmit = async (_token) => {
    //ev.preventDefault();
    let { interestIds } = this.state
    try {//, {params: { limit: this.state.items }
      //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
      this.setState({ disableSubmit: true });
      const res = await apiRequest.newsletter.update({ interestIds: interestIds });
      //const res = await axios.put('/api/v1/newsletters', { interestIds: interestIds, headers: _token });
      if (res.status === 202 && this._isMounted) {
        //setTimeout(()=>_this.getNewsletterSubscription(),4000);
        checkLocalStorage('subcribed_emails', interestIds);
        this.setState({
          disableSubmit: false,
          message: { subscriptionUpdated: true }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value;
    this.setState({
      ...this.state,
      [name]: value
    });
  }

  handleClose() {
    this.setState({ message: {} })
  }

  renderSubscribeEmail(list) {
    const { t, classes } = this.props;
    const { disableSubmit, interestIds } = this.state;
    //console.log(list);
    //console.log(interestIds);

    return (
      list.map((items, i) =>
        <Grid container direction="column" key={"group_" + i} alignItems="center" spacing={3}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Typography variant="h5" className={classes.name} style={{ width: '100%', paddingBottom: 4, borderBottom: 'solid 1px black' }}>{items.name}</Typography>
          </Grid>
          <Grid item xs={11}>
            {items.plans.map((item, j) =>
              <Grid container direction="column" key={"item_" + j}>
                <Grid item xs={12} key={item.interestId}>
                  <Switch
                    onChange={() => { this.handleEmailChange(item.interestId) }}
                    label={<><Typography variant="h6" className={classes.name}>{t("newsletter:" + item.slug)}</Typography><Typography variant="body2" gutterBottom color="textSecondary">{t("newsletter:" + item.slug + "_caption")}</Typography></>}
                    checked={(interestIds.indexOf(item.interestId) > -1) ? true : false}
                    name={item.name}
                    position="start"
                    fullwidth="true"
                    className={clsx([classes.switch])}
                    disabled={disableSubmit}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )
    )
  }

  render() {
    const { t, classes } = this.props;
    const { message, newsletters, disableSubmit } = this.state;
    //console.log(newsletters, interestIds);

    const headerData = {
      header: t("enewsletter:newsletters"),
      caption: t("enewsletter:newsletters_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.subscriptionUpdated,
        onClose: this.handleClose,
        severity: "success",
        children: t("enewsletter:subscription_updated")
      }
    ]

    const footerData = [
      <Button onClick={this.handleSubmit} fullWidth disabled={disableSubmit}>{t("common:submit")}</Button>
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          {!isEmpty(newsletters) && this.renderSubscribeEmail(newsletters)}
        </Box>

        <RenderFooter data={footerData} />
      </Container>
    )
  }
}

export default withTranslation(["common", "errors", "enewsletter", "newsletter"])(withStyles(useStyles)(SubscribeEmails));