import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { RenderPaymentStatusHeader as useStyles } from "./Styles";

RenderPaymentStatusHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string.isRequired,
  severity: PropTypes.string
}

export default function RenderPaymentStatusHeader(props) {
  const { title, children, severity } = props;
  const classes = useStyles(props);

  return (
    <Alert severity={severity || "success"} classes={{ root: classes.root, icon: classes.icon }}>
      {title && <AlertTitle variant="h4" className={classes.title}>{title}</AlertTitle>}
        {children}
    </Alert>
  )
}