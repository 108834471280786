import React from "react";
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from "@material-ui/core";
import { RenderHeader as useStyles } from "./Styles";

export default function RenderHeader(props) {
  const { headerBefore, header, caption } = props;
  const classes = useStyles(props);

  return (
    <Box component="header" className={classes.root}>
      <Grid container className={classes.container}>
        {headerBefore && <Grid item className={classes.headerBeforeItem}>{headerBefore}</Grid>}
        <Grid item xs className={classes.headerItem}><Typography variant="h4" gutterBottom={Boolean(caption)} className={classes.header}>{header}</Typography></Grid>
        {caption && <Grid item xs={12} className={classes.captionItem}><Typography variant="body1" className={classes.caption}>{caption}</Typography></Grid>}
      </Grid>
    </Box>
  )
}

RenderHeader.propTypes = {
  headerBefore: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  header: PropTypes.string.isRequired,
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  theme: PropTypes.string
}