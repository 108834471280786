import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import authService from '../api-authorization/AuthorizeService';
import clsx from "clsx";
import { IconButton, Drawer as MuiDrawer, Grid, List as MuiList, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from "@material-ui/icons";
import { RenderDialog } from "../UI/Core";
import { Menu as useStyles } from "./Styles";

export default function Menu(props) {
  const { drawer } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const populateState = async() => {
      const isAuthenticated = await authService.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
    }
    populateState();
  })


  if (isAuthenticated) {
    if (drawer) {
      return <Drawer {...props} />;
    } else {
      return <SideBar {...props} />;
    }
  } else {
    return null;
  }
}

Menu.propTypes = {
  drawer: PropTypes.bool,
  anchor: PropTypes.oneOf(["bottom", "left", "right", "top"])
}

function Drawer(props) {
  const { anchor } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);

  return (
    <Fragment>
      <IconButton edge="end" onClick={() => setOpen(true)} className={classes.button} ><MenuIcon /></IconButton>
      <MuiDrawer anchor={anchor} open={open} onClose={() => setOpen(false)} classes={{ root: classes.drawer, paper: classes.paper }}>
        <List {...props} onClose={() => setOpen(false)} />
      </MuiDrawer>
    </Fragment>
  )
}

function SideBar(props) {
  const classes = useStyles(props);

  return (
    <Grid item className={classes.sideBar}>
      <List {...props} />
    </Grid>
  )
}

function List(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);
  let urls = [];

  const handleClose = () => {
    setOpen(false)
  }

  const dialogData = [
    {
      open: open,
      onClose: handleClose,
      link: { pathname: "/authentication/logout", state: { local: true } },
      title: t("common:confirm_logout"),
      children: t("common:confirm_logout_content"),
      cancel: true
    }
  ]

  let data = [
    {
      string: t("dashboard:dashboard"),
      url: "/manage"
    },
    {
      string: t("profile:profile"),
      url: "/manage/profile"
    },
    // {
    //   string: t("subscription:corporate_subscription"),
    //   url: "/manage/corporate"
    // },
    {
      string: t("subscription:subscriptions"),
      url: "/manage/subscriptions"
    },
    {
      string: t("payment:payment_method"),
      url: "/manage/payment-method"
    },
    {
      string: t("address:billing_and_shipping"),
      url: "/manage/addresses"
    },
    {
      string: t("enewsletter:newsletters"),
      url: "/manage/subscribe-emails"
    },
    {
      string: t("common:change_password"),
      url: "/manage/password"
    },
    {
      string: t("common:signout"),
      type: "func",
      func: () => { setOpen(true) }
    }
  ]

  // if('true' !== process.env.REACT_APP__FEATURE__CORPORATE) {
  //   data = data.filter(x => x.string !== t("subscription:corporate_subscription"));
  // }

  data.forEach((d)=>{
    if(d.url && d.url!=='/manage')
      urls.push(d.url);
  })
  //console.log(window.location.pathname);

  const isCurrUrl =(url)=>{
    //console.log(url, window.location.pathname);
    if(url && url!=='/manage')
      return (window.location.pathname === url)?true:false;
    else
      return false;
  }

  return (
    <MuiList data-cy='menu' component="nav" className={classes.list}>
      {data.map((item, key) => {
        switch (item.type) {
          case "func":
            return (
              <Fragment key={key} >
                <RenderDialog data={dialogData} />
                <MenuItem button 
                  onClick={item.func}
                  className={clsx(
                    classes.item,
                    (
                      (item.url === window.location.pathname) ||
                      ((item.url !== '/manage') && window.location.pathname.indexOf(item.url) > -1) ||
                      (window.location.pathname.indexOf('/subscription/') > -1 && item.url === "/manage/subscriptions")||
                      ( ((window.location.pathname.indexOf('/manage/licenses') > -1) || (window.location.pathname.indexOf('/manage/add-tenants') > -1) || (window.location.pathname.indexOf('/manage/corporate') > -1))  && item.url === "/manage/corporate")
                    ) ? classes.highlight : ''
                  )}
                >{item.string}</MenuItem>
              </Fragment>
            )
          /*case "route":
            return (
              <MenuItem key={key} button component={Link}
                to={item.noParams ? item.url : item.url + postfix}
                className={clsx(
                  classes.item,
                  (
                    (item.url === window.location.pathname) ||
                    ((item.url !== '/manage') && window.location.pathname.indexOf(item.url) > -1) ||
                    (window.location.pathname.indexOf('/subscription/') > -1 && item.url === "/manage/subscriptions")
                  ) ? classes.highlight : ''
                )}
              >{item.string}</MenuItem>
            )*/
          default:
            if(isCurrUrl(item.url)){
              return (
                <MenuItem key={key} button onClick={props.onClose}
                  className={clsx(classes.item,classes.highlight)}
                >{item.string}</MenuItem>
              )
            }else{
              return (
                <MenuItem key={key} button component={Link} onClick={props.onClose}
                  to={item.url}
                  className={clsx(
                    classes.item,
                    (
                      (item.url === window.location.pathname) ||
                      ((item.url !== '/manage') && window.location.pathname.indexOf(item.url) > -1) ||
                      (window.location.pathname.indexOf('/subscription/') > -1 && item.url === "/manage/subscriptions")||
                      ( ((window.location.pathname.indexOf('/manage/licenses') > -1) || (window.location.pathname.indexOf('/manage/add-tenants') > -1) || (window.location.pathname.indexOf('/manage/corporate') > -1))  && item.url === "/manage/corporate")
  
                    ) ? classes.highlight : ''
                  )}
                >{item.string}</MenuItem>
              )
            }
            
        }
      })}
    </MuiList>
  )
}