import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { GatewayContext } from "../../utils/GatewayContext";
import { isEmpty } from "../../utils/Utils";
import clsx from "clsx";
import { Box, Grid, Typography } from "@material-ui/core";
import { RenderSectionTitle } from "./Core";
import { RenderAddress as useStyles } from "./Styles";

RenderAddress.propTypes = {
  userAddress: PropTypes.object.isRequired,
}

function RenderAddress(props) {
  const { t, userAddress } = props;
  const { billingAddress, phone, shippingAddress } = userAddress;

  const classes = useStyles(props);

  //console.log(userAddress);

  return (
    <Box component="section" className={classes.root}>
      <Grid container className={clsx(classes.container, classes.address)}>
        <Grid item xs={12} md={6}>
          <RenderSectionTitle data={t("address:billing_address")} />
          {!isEmpty(userAddress) &&
            <Grid container className={classes.gridContainer}>
              <Grid item xs>
                <Typography variant="body1">
                  {billingAddress.line1 && <Fragment>{billingAddress.line1}</Fragment>}
                  {billingAddress.line2 && <Fragment><br />{billingAddress.line2}</Fragment>}
                  {billingAddress.city && <Fragment><br />{billingAddress.city}</Fragment>}
                  {billingAddress.state && <Fragment><br />{billingAddress.state}</Fragment>}
                  {billingAddress.postalCode && <Fragment><br />{billingAddress.postalCode}</Fragment>}
                  <br />
                  <GatewayContext.Consumer>
                    {context =>
                      context.countries && Object.keys(context.countries).map(key =>
                        billingAddress.countryCode === context.countries[key].code && context.countries[key].name
                      )
                    }
                  </GatewayContext.Consumer>
                  <br />{'(+'+phone.prefix+') '+phone.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>

        <Grid item xs={12} md={6}>
          <RenderSectionTitle data={t("address:shipping_address")} />
          {!isEmpty(shippingAddress) &&
            <Grid container className={classes.gridContainer}>
              <Grid item xs>
                <Typography variant="body1">
                  {shippingAddress.address.line1 && <Fragment>{shippingAddress.address.line1}</Fragment>}
                  {shippingAddress.address.line2 && <Fragment><br />{shippingAddress.address.line2}</Fragment>}
                  {shippingAddress.address.city && <Fragment><br />{shippingAddress.address.city}</Fragment>}
                  {shippingAddress.address.state && <Fragment><br />{shippingAddress.address.state}</Fragment>}
                  {shippingAddress.address.postalCode && <Fragment><br />{shippingAddress.address.postalCode}</Fragment>}
                  {shippingAddress.address.countryCode &&
                    <Fragment>
                      <br />
                      <GatewayContext.Consumer>
                        {context =>
                          context.countries && Object.keys(context.countries).map(key =>
                            shippingAddress.address.countryCode === context.countries[key].code && context.countries[key].name
                          )
                        }
                      </GatewayContext.Consumer>
                    </Fragment>
                  }
                  <br />{'+('+shippingAddress.phone.prefix+') '+shippingAddress.phone.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default withTranslation(["common", "address"])(RenderAddress);