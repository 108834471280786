import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
//import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = [ process.env.REACT_APP_LANGUAGES_FALLBACK ]; 
// const lng = "en";
const availableLangs = (process.env.REACT_APP_LANGUAGES).split(',').map(l => l.trim());


i18n
  .use(detector)
  //.use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // i18n.lng property should not be set to when using language detector
    //lng: lng,
    fallbackLng: fallbackLng, // use en if detected lng is not available
    whitelist: availableLangs,

    saveMissing: true, // send not translated keys to endpoint

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }, 
    react: { 
      useSuspense: false //   <---- this will do the magic
    },
    detection: {
      order: ['querystring'],
      lookupQuerystring: 'lang'
    },
    resources:{}
  });

i18n.on('languageChanged', function(lng) {
  //console.log('lang is changed ',lng,window.location.search);
})
export default i18n;