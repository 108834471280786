import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isEmpty} from "../../utils/Utils";
import clsx from "clsx";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { LegacySubscriptions as useStyles } from "./Styles";
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import { RenderHeader, RenderMessage, IconLabelButton, RenderFooter, Button, Typography } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";


class LegacySubscriptions extends Component {
  static displayName = LegacySubscriptions.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      message: {},
      token: {},
      paging: {},
      results: {},
      items: 20,
      plansItems: 50,
      focus: {}
    }
    // subscription status
    // trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid
    //this.getPlans = this.getPlans.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    const { items } = this.state;
    this._isMounted = true;
    try {
      const response = await apiRequest.subscription.legacy({ limit: items });
      //console.log(this._isMounted);
      if (response.status === 200 && this._isMounted)
        this.setState({...response.data})

    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404 || err.response.status === 401) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }
  componentWillUnmount() {
    //console.log('unmount');
    this._isMounted = false;
  }
  handleClose() {
    this.setState({
      message: {}
    });
  }

  render() {
    const { t, classes, showFooter } = this.props
    const { message, paging, results: subscriptions  } = this.state;

    const headerData = {
      header: t("subscription:legacy_subscriptions"),
      caption: t("subscription:legacy_subscriptions_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      showFooter && <Button color="secondary" component={Link} to={"/manage/subscriptions"} fullWidth >{t("common:back")}</Button>
    ]

    if (isEmpty(subscriptions)) {
      return null;
    }

    return (
      <Container maxWidth="md" className={classes.root}>
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={clsx(classes.gridContainer, classes.th)}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" className={classes.title}>{t("subscription:subscriptions")}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body1" className={clsx(classes.title, classes.period)}>{t("subscription:period")}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
            <Typography variant="body1" className={clsx(classes.title, classes.status)} >{t("subscription:status")}</Typography>
            </Grid>
          </Grid>
          {subscriptions.map((item, key) => <RenderLegacySubscription key={key} item={item} t={t} classes={classes} />)}
          {paging.hasMore &&
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} className={classes.item}>
                <Box textAlign="center">
                  <IconLabelButton key={subscriptions.length} startIcon={<ArrowDownwardIcon />}>{t("common:show_more")}</IconLabelButton>
                </Box>
              </Grid>
            </Grid>
          }
        </Box>

        <RenderFooter data={footerData} />
        {/* <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid> */}
      </Container>
    )
  }
}

function RenderLegacySubscription(props) {
  const { item, t, classes } = props;

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} md={6}>
        <Typography variant="body1">{item.product}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body1" className={classes.period}>{item.from} {t("subscription:to")} {item.to}</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        {item.status === "Active" ? <Typography color="success" variant="body1" className={classes.status}>{item.status}</Typography> : <Typography color="error" variant="body1" className={classes.status}>{item.status}</Typography>}
      </Grid>
    </Grid>
  )
}

export default withTranslation(["common", "subscription", "errors"])(withStyles(useStyles)(LegacySubscriptions));