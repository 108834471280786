import React, { Component } from 'react';
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { GatewayContext } from '../../utils/GatewayContext';
import { isEmpty, renewTokenHeader, groupBy } from "../../utils/Utils";
import apiRequest from "../../utils/apiRequest";
import clsx from "clsx";
import { Container, Box, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon, VerifiedUser as VerifiedUserIcon, Help as HelpIcon, Error as ErrorIcon } from '@material-ui/icons';
import { Profile as useStyles } from "./Styles";
import EditInfo from "./EditInfo";
import WorkEmail from "./WorkEmail";
import { RenderHeader, RenderMessage, RenderDialog, RenderFeatureButton, RenderSectionTitle, RenderSectionTitleWithFeature, RenderDisplaySection, IconLabelButton, IconButton } from "../UI/Core";

class Profile extends Component {
  static displayName = Profile.name;

  constructor(props) {
    super(props);
    const params = queryString.parse(props.location.search);
    this.state = {
      id: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      selectedEmail: '',
      languageCode: '',
      companyName: '',
      jobTitle: '',
      countryCode: '',
      industryCodeParent:"",
      industryCode: "",
      industryParentList:[],
      industryList:[],
      returnurl: params.returnurl || "",
      message: {},
      token: {},
      disableSubmit: false,
      workEmails: [],
      workEmailPaging:{},
      //isWorkemail:(props.match.params && props.match.params.type && (props.match.params.type==='workemail'))?true:false,
      edit: (props.match.params && props.match.params.type)?props.match.params.type:undefined
    };

    this.deleteWorkEmail = this.deleteWorkEmail.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.confirmDeleteWorkEmail = this.confirmDeleteWorkEmail.bind(this);
    this.renderWorkEmail = this.renderWorkEmail.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderIndustryCodes = this.renderIndustryCodes.bind(this);
    this.gotoEdit = this.gotoEdit.bind(this);
    this.updateEditVals = this.updateEditVals.bind(this);
    this.checkPath = this.checkPath.bind(this);

    this.unlisten= null;
  }


  async componentDidMount() {
    const {industryParentList, industryCode, industryList} = this.state;
    const {industryCodes} = this.context;
    let _state = {}, _item;
    if(isEmpty(industryParentList)){
      _state['industryParentList'] = groupBy(industryCodes, "groupName");
    }else
      _state['industryParentList'] = industryParentList;

    if(industryCode!=='' && isEmpty(industryList)){
      _item = industryCodes.find((o)=>{
        return (o.code===industryCode)
      });
      _state['industryParentCode'] = _item.groupName;
      _state['industryList'] = _state['industryParentList'].find(o=>{return (o.key===_item.groupName)}).items;
      this.setState(_state);
    }

    try {
      const profile = await apiRequest.account.profile('Unknown');
      const workemails = await apiRequest.workemails.list();
      //console.log(profile.data.result, workemails.data.results);
      this.setState({...this.state,
        ...profile.data.result,
        workEmails:workemails.data.results,
        workEmailPaging:workemails.data.paging 
      });

    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
    this.checkPath(window.location);
    this.unlisten = this.props.history.listen((location, action) => {
      this.checkPath(location);
    });
  }
  componentWillUnmount() {
    if(this.unlisten)
      this.unlisten();
  }

  checkPath(location){
    let paths = location.pathname.split('/');
      //console.log(paths.indexOf("edit"), paths.indexOf("personal"), paths.indexOf("company"),paths.indexOf("workemail"));
      if (paths.indexOf("personal") > -1)
        this.setState({edit: 'personal' });
      else if (paths.indexOf("company") > -1)
        this.setState({edit: 'company' });
      else if (paths.indexOf("workemail") > -1)
        this.setState({edit: 'workemail' });
      else
        this.setState({edit: '' });
  }

  updateEditVals(data){
    this.setState({...this.state, ...data});
  }

  gotoEdit(type, message) {
    let _this = this;
    if (type === "personal" || type === "company" || type === "workemail") {
      this.props.history.push({ pathname: "/manage/profile/edit/" + type});
      if(type==='workemail')
        this.setState({ message: {}, type:type });
    }else if (message && !type) {
      this.props.history.replace('/manage/profile/');
      setTimeout(async ()=>{
        let profile = await apiRequest.account.profile('Unknown');
        //console.log(profile.data.result);
        _this.setState({..._this.state,
          ...profile.data.result, 
          workEmails: _this.state.workEmails,
          workEmailPaging: _this.state.workEmailPaging
        });
      }, 1000);
      if (message === "personalUpdated") {
        this.setState({ message: { personalUpdated: true }, edit: type });
      } else if (message === "companyUpdated") {
        this.setState({ message: { companyUpdated: true }, edit: type });
      }
    } else {

      this.props.history.replace('/manage/profile/');
      this.setState({edit: type, message: {}, });
      
    }
  }

  async deleteWorkEmail(_token, trial) {
    try {
      const res = await apiRequest.workemails.delete();
      if (res.status === 204) {
        _token = await renewTokenHeader();
        setTimeout(async ()=>{
          const workemails = await apiRequest.workemails.list();
          this.setState({
            workEmails:workemails.data.results,
            workEmailPaging:workemails.data.paging 
          });
          return;
        },2000);
        
        this.setState({
          selectedEmail: '',
          message: {
            workemailRemoved: true,
            confirmDeleteWorkEmail: false
          }
        })
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        if(trial > this.maxTrial)
          this.setState({ message: { badRequestError: true }});
        else{
          _token = await renewTokenHeader();
          this.deleteWorkEmail(_token, trial++);
          this.setState({ token: _token })
        }
      } else if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: {
            badRequestError: true,
            confirmDeleteWorkEmail: false
          }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: {
            internalServerError: true,
            confirmDeleteWorkEmail: false
          }
        });
      }
    }
  }

  async confirmDeleteWorkEmail(email) {
    //await this.deleteWorkEmail(this.state.token, email);
    this.setState({
      selectedEmail: email,
      message: {
        confirmDeleteWorkEmail: true
      }
    })

  }

  handleClose() {
    this.setState({ message: {} })
  }

  renderWorkEmail() {
    const { classes } = this.props;
    const { workEmails } = this.state;

    return (
      <Box component="section" className={classes.workEmailRoot}>
        {workEmails.map((item, key) => (
          item && (
            <Box key={key} className={classes.workEmailContainer}>
              <Grid container className={classes.workEmailRow}>
                <Grid item className={classes.workEmailIconItem}>
                  {(item.status === "Verified") ?
                    <VerifiedUserIcon fontSize="small" className={clsx(classes.workEmailIcon, classes.success)} />
                    : (item.status === "Pending") ? <HelpIcon color="disabled" fontSize="small" className={classes.workEmailIcon} />
                      : (item.status === "Inactive") ? <ErrorIcon color="disabled" fontSize="small" className={classes.workEmailIcon} />
                        : null
                  }
                </Grid>
                <Grid item xs className={classes.workEmailValueItem}>
                  <Typography variant="body1" className={classes.workEmailValue}>
                    {item.email}
                  </Typography>
                </Grid>
                <Grid item xs className={classes.workEmailFeatureItem}>
                  <IconButton color="error" size="small" onClick={() => this.confirmDeleteWorkEmail(item.email)} className={classes.workEmailButton}><DeleteIcon /></IconButton>
                </Grid>
              </Grid>
            </Box>
          )
        ))}
      </Box>
    )
  }

  renderName() {
    const { t, i18n } = this.props;
    const { title, firstName, lastName } = this.state;

    return (
      !(i18n.language === "zh-CN") ?
        `${t("titles:" + title)} ${firstName} ${lastName}`
        : `${firstName} ${lastName} ${t("titles:" + title)}`
    )
  }

  renderIndustryCodes() {
    const { t } = this.props;
    const { industryCode } = this.state;

    return (
      <GatewayContext.Consumer>
        {context =>{          
          return (
            context.industryCodes.map(item =>
              (item.code === industryCode) && t("industries:" + item.code)
            )
          )
        }}
      </GatewayContext.Consumer>
    )
  }

  render() {
    const { t, classes } = this.props;
    const { message, returnurl, edit, title, firstName, lastName, email, languageCode, companyName, workEmails, jobTitle, countryCode, industryCode } = this.state;
    //const RenderWorkEmail = this.renderWorkEmail;
    const RenderName = this.renderName;
    const RenderIndustryCodes = this.renderIndustryCodes;

    // page data
    const headerData = {
      header: t("profile:profile"),
      caption: t("profile:profile_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.personalUpdated,
        onClose: this.handleClose,
        severity: "success",
        children: t("profile:personal_info_is_updated")
      },
      {
        open: message.companyUpdated,
        onClose: this.handleClose,
        severity: "success",
        children: t("profile:company_info_is_updated")
      },
      {
        open: message.workemailRemoved,
        onClose: this.handleClose,
        severity: "success",
        children: t("profile:work_email_is_removed")
      }
    ]

    const dialogData = [
      {
        open: message.confirmDeleteWorkEmail,
        onClose: this.handleClose,
        onConfirm: this.deleteWorkEmail,
        title: t("profile:confirm_delete_work_email"),
        children: t("profile:confirm_delete_work_email_content")
      }
    ]

    const workEmailData = {
      header: t("profile:work_email"),
      feature: <IconLabelButton startIcon={<AddIcon />} size={isEmpty(workEmails) ? undefined : "small"} onClick={() => { this.gotoEdit('workemail') }}>{t(isEmpty(workEmails) ? "profile:add_work_email" : "profile:add")}</IconLabelButton>,
    }

    const personalData = {
      header: t("profile:personal_information"),
      feature: <IconLabelButton startIcon={<EditIcon />} size="small" onClick={() => { this.gotoEdit('personal') }} >{t("profile:edit")}</IconLabelButton>,
      data: [
        title && firstName && lastName && {
          title: t("profile:name"),
          value: <RenderName />
        },
        email && {
          title: t("profile:email"),
          value: email
        },
        languageCode && {
          title: t("profile:email_language"),
          value: (languageCode === "en") ? "English" :
            (languageCode === "zh-CN") ? "简体中文" :
              (languageCode === "ja") && "日本語",
        }
      ]
    }

    const companyData = {
      header: t("profile:company_information"),
      feature: <IconLabelButton startIcon={<EditIcon />} size="small" onClick={() => { this.gotoEdit('company') }} >{t("profile:edit")}</IconLabelButton>,
      data: [
        companyName && {
          title: t("profile:company_name"),
          value: companyName
        },
        jobTitle && {
          title: t("profile:job_title"),
          value: jobTitle
        },
        industryCode && {
          title: t("profile:organisation_type"),
          value: <RenderIndustryCodes />
        },
        countryCode && {
          title: t("profile:region"),
          value: t("regions:" + countryCode)
        }
      ]
    }
    //console.log(workEmails,edit,isEmpty(workEmails));
    if (edit==='personal' || edit==='company') {
      return (
        <EditInfo {...this.props} profile={this.state} type={edit} updateEditVals={this.updateEditVals} onCloseEdit={this.gotoEdit} />
      );
    }else if(edit==='workemail'){
      return (
        <WorkEmail location={this.props.location} returnurl={returnurl} onCloseEdit={()=>this.gotoEdit('workEmail')} />
      );
    }else {
      return (
        <Container maxWidth="sm" className={classes.root}>
          <RenderHeader {...headerData} />
          <RenderMessage data={messageData} />
          <RenderDialog data={dialogData} />
          {isEmpty(workEmails) && <RenderFeatureButton data={[workEmailData.feature]} />}
          {!(isEmpty(workEmails)) &&
            <Box data-cy="workemail" className={classes.sectionRoot}>
              {/* {workEmailData.header && <RenderSectionTitleWithFeature data={{ ...workEmailData, feature: '' }} />} */}
              <RenderSectionTitle data={workEmailData.header} />
              {this.renderWorkEmail()}
            </Box>
          }
          <Box data-cy="personal" className={classes.sectionRoot}>
            <RenderSectionTitleWithFeature data={personalData} />
            <RenderDisplaySection data={personalData.data} />
          </Box>
          <Box data-cy="company" className={classes.sectionRoot}>
            <RenderSectionTitleWithFeature data={companyData} />
            <RenderDisplaySection data={companyData.data} />
          </Box>
        </Container>
      )
    }

  }
}
Profile.contextType = GatewayContext;

export default withTranslation(["common", "profile", "error", "titles", "regions", "group_names", "industries"])(withStyles(useStyles)(Profile));