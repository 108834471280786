import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { injectStripe } from "react-stripe-elements";
import { isEmpty, tokenHeader, renewTokenHeader } from "../../utils/Utils";
import { Container, Box, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Payment as PaymentIcon } from "@material-ui/icons";
import { Checkout as useStyles } from "./Styles";
import apiRequest from "../../utils/apiRequest";
import { GatewayContext } from "../../utils/GatewayContext";
import { Backdrop, RenderHeader, RenderDialog, RenderSection, RenderFooter, RenderPlanTable, RenderPaymentMethod, RenderAddress, Typography, Button, ErrorMessage, IconLabelButton } from "../UI/Core";

import { Grid, TextField } from "@material-ui/core";

class Checkout extends Component {
  static displayName = Checkout.name;
  constructor(props) {
    super(props);
    this.state = {
      userAddress: {},
      selectedPlan: {},
      selectedPayment: {},
      success: false,
      invoiceNumber: "",
      receiptNumber: "",
      errors: {},
      message: {},
      token: "",
      disableSubmit: false,
      paymentResult: {},
      paymentIntent: {},
      previewData: undefined,
      coupon: '',
      lockCoupon: false
    }
    this.handlePay = this.handlePay.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.setReferenceNumber = this.setReferenceNumber.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.handleClearCoupon = this.handleClearCoupon.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    //this.renderCheckOrder = this.renderCheckOrder.bind(this);
  }

  async componentDidMount() {
    //console.log(this.context);
    this.setState({ token: await tokenHeader() });
  }

  handleComplete(e) {
    e.preventDefault();
    this.props.nextStepHandler({ step: this.props.step });
  }

  async setReferenceNumber(stripeInvoiceId, _token) {
    _token = (_token && _token.Authorization) ? _token : this.state.token;
    try {
      const invoiceRes = await axios.get("/api/v1/invoices", { params: { stripeInvoiceId } });

      //console.log(invoiceRes);
      if (invoiceRes.status === 200) {
        //console.debug(invoiceRes.data.result);

        let refNo = invoiceRes.data.result;
        this.setState({ refNo })
      }
    } catch (err) {
      if (err.response.status === 401) {
        _token = await renewTokenHeader();
        this.setReferenceNumber(stripeInvoiceId, _token);
        this.setState({ token: _token });
      } else if (err.response.status === 400) {
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  handlePaymentSuccess(result) {
    //console.log(result);
    this.setState({
      success: true,
      invoiceNumber: (result.stripeInvoiceNumber) ? result.stripeInvoiceNumber : '',
      receiptNumber: (result.stripeReceiptNumber) ? result.stripeReceiptNumber : ''
    });
    //await this.setReferenceNumber(stripeInvoiceId, this.state.token)
    //this.props.nextStepHandler({ step: this.props.step });

  }

  handleNextStep(result) {
    //localStorage.removeItem("paymentGateWayPlan");
    //console.log(result);
    if (result.result && result.paymentIntent)
      this.props.nextStepHandler({ step: this.props.step, ...result });
    else if (!result.paymentIntent && result.result)
      this.props.nextStepHandler({ step: this.props.step, result: result.result, paymentIntent: {} });

    /*this.setState({ 
      success: true, 
      invoiceNumber: (result.stripeInvoiceNumber)?result.stripeInvoiceNumber:'', 
      receiptNumber: (result.stripeReceiptNumber)?result.stripeReceiptNumber:''});
*/
  }

  async stripePay(result) {
    const { stripe } = this.props;
    const { clientSecret } = result;
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);
      
      /* error
        charge: "ch_1HPlJUEwLysLwiwKEGZmlhSO"
        code: "card_declined"
        decline_code: "insufficient_funds"
        doc_url: "https://stripe.com/docs/error-codes/card-declined"
        message: "Your card has insufficient funds."
        payment_intent: {id: "pi_1HPlJ3EwLysLwiwK5xfGnShc", object: "payment_intent", amount: 700, canceled_at: null, cancellation_reason: null, …}
        payment_method: {id: "pm_1HPl3KEwLysLwiwKOZ5v2PHi", object: "payment_method", billing_details: {…}, card: {…}, created: 1599726166, …}
        type: "card_error"

        result
        clientSecret: "pi_1HPlJ3EwLysLwiwK5xfGnShc_secret_WRuvHHtIfiG44gRtV4xBBz9DZ"
        invoiceStripeStatus: "open"
        paymentIntentStripeStatus: "requires_action"
        stripeInvoiceId: "in_1HPlJ3EwLysLwiwKBjKaQQkp"
        stripeInvoiceNumber: "577D2D70-0003"
        stripeSubscriptionId: "sub_Hzkou6z4ecVRH5"
        subscriptionStripeStatus: "incomplete"
      */
      if (error) {
        console.log(error);
        this.setState({
          disableSubmit: false,
          paymentResult: result,
          paymentIntent: paymentIntent,
          // message: {
          //   stripeErrors: true,
          //   errorsTitle: errors.title,
          //   errorsMessage: errors.message
          // }
        });
        if (!isEmpty(result)) this.handleNextStep({ result: result, paymentIntent: paymentIntent });
      } else if (paymentIntent.status === "succeeded") {
        this.handleNextStep({ result: result, paymentIntent: paymentIntent });
      } else {
        this.handleNextStep({ result: result });
      }
    } catch (err) {
      //alert(err);

      if (err.response.status === 400) {
        this.setState({
          disableSubmit: false,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          message: { internalServerError: true }
        });
      }
    }
  }

  async handlePay() {
    //const { selectedPayment, selectedPlan } = this.state;
    const { selectedPlan, selectedPayment } = this.context;
    //console.log(selectedPlan, selectedPayment);return;
    const { previewData, coupon } = this.state;
    const data = {
      paymentMethodId: selectedPayment.id,
      coupon: (previewData) ? coupon : '',
      //cancelAtPeriodEnd: false,
      plans: [{ planId: selectedPlan.id, quantity: 1 }]
    };
    //console.log(data);return;
    this.setState({ disableSubmit: true });
    try {
      //const res = await axios.post("/api/v1/checkouts", data, { headers: _token });
      const res = await apiRequest.checkout.checkout(data);
      //console.log(res);
      if (res.status === 201) {
        this.handleNextStep({ result: res.data.result, paymentIntent: {} });
      } else if (res.status === 200) {
        //const { clientSecret, stripeInvoiceId } = res.data.result;
        this.stripePay(res.data.result);
      }
      else if (res.status === 204) {
        // Successful subscription and NO next action is required.
        //localStorage.removeItem("paymentGateWayPaymentId");
        localStorage.removeItem("paymentGateWayPlan");
        this.setState({ success: true, disableSubmit: false });
      }
    } catch (err) {
      if (err.response.status === 402) {
        this.handleNextStep({ result: err.response.data.result, paymentIntent: {} });
      } else if (err.response.status === 400 || err.response.status === 403) {
        //401: Unauthorized //403: Forbidden
        this.setState({
          disableSubmit: false,
          success: true,
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          disableSubmit: false,
          success: true,
          message: { internalServerError: true }
        });
      }
    }
  }

  handlePrevStep(e) {
    this.props.prevStepHandler({ step: this.props.step });
  }

  handleClose() {
    this.setState({ message: {} });
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  async handleApplyCoupon() {
    const { coupon, errors } = this.state;
    const { selectedPlan, selectedPayment } = this.context;
    //console.log(selectedPlan, selectedPayment);

    if (coupon === '') {
      this.setState({ errors: { ...errors, coupon: "errors:is_required" } })
    } else {
      this.setState({ errors: { ...errors, coupon: "" } })
      try {
        const data = {
          paymentMethodId: selectedPayment.id,
          plans: [{ planId: selectedPlan.id, quantity: 1 }],
          coupon: coupon
        };
        const res = await apiRequest.checkout.preview(data);
        if (res.status === 200) {
          //console.log(res.data.result);
          const { discountCoupons } = res.data.result;
          if (discountCoupons[0].error) {
            this.setState({
              previewData: undefined,
              message: { couponCodeFailed: true },
              errors: { coupon: (discountCoupons[0].error.code) ? "payment:" + discountCoupons[0].error.code : "payment:invalid_coupon_caption" },
              lockCoupon: false
            });
          } else {
            this.setState({
              previewData: res.data.result,
              message: { couponCodeSuccess: true },
              lockCoupon: true
            })
          }
        }
      } catch (err) {
        if (err.response.status === 400 || err.response.status === 403) {
          //403: Forbidden
          console.log(err.response.data.error.message);
          this.setState({
            message: { badRequestError: true }
          });
        } else if (err.response.status === 500) {
          this.setState({
            message: { internalServerError: true }
          });
        }
      }
    }
  }

  handleClearCoupon() {
    this.setState({
      previewData: undefined,
      coupon: '',
      lockCoupon: false
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    //console.log(this.props);
    const { t, classes } = this.props;
    const { message, disableSubmit, coupon, errors, lockCoupon, previewData: statePreviewData } = this.state;

    // page data
    const headerData = {
      header: t("payment:check_order"),
      caption: t("payment:check_order_caption")
    }

    const dialogData = [
      {
        open: message.couponCodeSuccess,
        onClose: this.handleClose,
        severity: "success",
        title: t("payment:congratulations"),
        children: t("payment:valid_coupon_caption")
      },
      {
        open: message.couponCodeFailed,
        onClose: this.handleClose,
        severity: "error",
        title: t("payment:unforturnately"),
        children: t("payment:invalid_coupon_caption")
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const remarkData = [
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("payment:subscription_terms", { link: "/terms", className: classes.link }) }} className={classes.remark} />
    ]

    //console.log(errors);

    return (
      <GatewayContext.Consumer>
        {({ selectedPlan, selectedPayment, userAddress, previewData }) => (
          <Container maxWidth="md" className={classes.root}>
            <Backdrop variant="processing" open={disableSubmit} />
            <RenderHeader {...headerData} />
            <RenderDialog data={dialogData} />
            <RenderAddress userAddress={userAddress} />
            <RenderPlanTable {...previewData} {...statePreviewData} />
            <RenderPaymentMethod paymentMethod={selectedPayment} />

            <Box component="section" className={classes.sectionRoot}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.title}>{t("payment:coupon_code")}</Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md={6}>
                  <TextField onChange={this.handleInputChange} name="coupon" variant="outlined" fullWidth={true} value={coupon} error={Boolean(errors["coupon"])} InputProps={(coupon && !lockCoupon) ? {
                    endAdornment: <InputAdornment position="end">
                      <IconLabelButton
                        onClick={() => this.handleApplyCoupon(selectedPlan, selectedPayment)}
                        onMouseDown={this.handleMouseDownPassword}
                      >{t("payment:apply")}</IconLabelButton>
                    </InputAdornment>
                  } : (coupon && statePreviewData.discountCoupons && lockCoupon) ? {
                    endAdornment: <InputAdornment position="end">
                      <IconLabelButton
                        color="warning"
                        onClick={this.handleClearCoupon}
                        onMouseDown={this.handleMouseDownPassword}
                      >{t("payment:clear")}</IconLabelButton>
                    </InputAdornment>
                  } : null} disabled={lockCoupon} />
                  {(errors.coupon && errors.coupon !== '') && <ErrorMessage>{t(errors["coupon"])}</ErrorMessage>}
                </Grid>
              </Grid>
            </Box>

            <RenderSection data={remarkData} />
            <RenderFooter data={[
              <Button color="secondary" fullWidth onClick={this.handlePrevStep} disabled={disableSubmit}>{t("common:back")}</Button>,
              <Button color="third" startIcon={<PaymentIcon />} fullWidth onClick={this.handlePay} disabled={Boolean((coupon && !statePreviewData) || disableSubmit)}>{t("payment:pay")}</Button>
            ]} />
          </Container>
        )}
      </GatewayContext.Consumer>
    );
  }
}
Checkout.contextType = GatewayContext;

export default withTranslation(["common", "payment", "errors"])(injectStripe(withStyles(useStyles)(Checkout)));