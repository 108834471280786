import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.dark, 0.08)
    }
  }
}));

export default function (props) {
  const { color, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <IconButton
      color={"primary"}
      className={clsx(classes.root, (color === "error") && classes.errorColor, className)}
      {...rest}
    >{props.children}</IconButton>
  )
}
