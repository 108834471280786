import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    },
    "& input[type=number]": {
      MozAppearance: "textfield"
    }
  }
}))

export default function (props) {
  const classes = useStyles(props);

  return (
    <MuiTextField
      variant="outlined"
      // margin="normal"
      fullWidth
      classes={{ root: classes.root }}
      {...props}
    />
  )
}