import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { AuthorizeFooter as useStyles } from "./Styles";
import { useTranslation } from "react-i18next";

export default function AuthorizeFooter(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container component='footer' className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item className={classes.item}>{t("common:copyright")}</Grid>
          <Grid item className={classes.item}>
            <Link to='/privacy' className={classes.link}>{t("common:privacy")}</Link>{t("common:and")}<Link to='/terms' className={classes.link}>{t("common:terms")}</Link>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}