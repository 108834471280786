import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { GatewayContext} from "../../utils/GatewayContext";
import { groupBy, isEmpty } from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import apiRequest from "../../utils/apiRequest";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, TextField, Select, ErrorMessage, Button } from "../UI/Core";
import { PopupButton } from '@typeform/embed-react';
import authService from '../api-authorization/AuthorizeService';
class EditInfo extends Component {
  static displayName = EditInfo.name;
  _context = this.context;
  constructor(props) {
    super(props);
    const {profile} = props;
    //console.log(profile);
    this.state ={
      sub: '',
      edit:props.match.params.type?props.match.params.type:'personal',
      disableSubmit: false, 
      message: {},
      errors: {},
      token: {},
      profile: profile,
      id: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      selectedEmail: '',
      languageCode: '',
      companyName: '',
      jobTitle:  '',
      countryCode: '',
      industryCodeParent: '',
      industryCode:  '',
      industryParentList: [],
      industryList: [],
    };
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.handleCompanySubmit = this.handleCompanySubmit.bind(this);
    this.renderParentList = this.renderParentList.bind(this);
    this.updateOrgList = this.updateOrgList.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.profile !== prevState.profile) {
      return {
        profile:nextProps.profile
      };
    }
    else return null; // Triggers no change in the state
  }
  componentDidUpdate(prevProps, prevState){
    //console.log(prevProps.profile, this.props.profile);
    if(prevProps.profile!== this.props.profile){
      //console.log('update');
      const { industryCodes } = this.context;
      const { industryCode } = this.props.profile;
      if(!isEmpty(industryCodes) && industryCode!==''){
        let result = this.updateOrgList(industryCode, industryCodes);
        this.setState({...this.props.profile,...result});
      }
    }
  }

  updateOrgList(industryCode, industryCodes){
    const industryParentList = groupBy(industryCodes, "groupName");
    //console.log(groupedJobTitles, industryCode);
    let item = industryCodes.find((o)=>{
      return (o.code===industryCode)
    })
    let plist = industryParentList.find((o)=>{
      return (o.key===item.groupName)
    })

    var sorted = industryParentList.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1)
    // Move 'Other' to the end
    sorted.push(sorted.splice(sorted.indexOf(sorted.find((a => a.key === 'Other'))), 1)[0]);

    return {industryCodeParent:item.groupName, industryList:plist.items, industryParentList:sorted};
  }

  async componentDidMount(){
    const { industryCodes } = this.context;
    const { industryCode } = this.props.profile;

    const { sub } = await authService.getUser();
    this.setState({ sub });

    //console.log(this.props.profile);
    if(!isEmpty(industryCodes) && industryCode!==''){
      let result = this.updateOrgList(industryCode, industryCodes);
      this.setState({...this.props.profile,...result});
    }else{
      this.setState({...this.props.profile});
    }
  }

  handleCompanySubmit = async () => {

    const {industryCodeParent, industryCode, industryList, industryParentList, companyName, jobTitle, countryCode } = this.state;
    //console.log(profile);
    //_token = (_token && _token.Authorization)?_token:this.state.token;
    // if (!this.handleValidation()) {
      try {
        //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
        this.setState({
          disableSubmit: true, 
          errors: {},
          message: {}
        });
        const data = {
          companyName:companyName,
          jobTitle:jobTitle,
          industryCode:industryCode,
          countryCode:countryCode, 
        }
        //const res = await axios.patch('/api/v1/account/job', { ...this.state, headers: _token });
        const res = await apiRequest.account.updateJob(data);
        if (res.status === 200) {
          this.props.onCloseEdit(false, 'companyUpdated');
          this.props.updateEditVals({...data, 
            industryCodeParent:industryCodeParent, 
            industryCode:industryCode, 
            industryList:industryList, 
            industryParentList:industryParentList});
          //window.location.href = '/manage/profile?message=companyUpdated'
        }
      } catch (err) {
        console.log(err);
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              errors[key] = "errors:" + error.params[key][0];
            });
            this.setState({ errors: errors })
          }
        } else if (err.response.status === 403) {
          //403: Forbidden
          //404: No such user
          this.setState({ message: { badRequestError: true } });
        } else if (err.response.status === 500) {
          this.setState({ message: { internalServerError: true } });
        }
      }
    // }
  }

  handleProfileSubmit = async () => {
    const {title, firstName, lastName, languageCode} = this.state;
    // if (!this.handleValidation('profile')) {
      try {
        //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
        this.setState({
          disableSubmit: true, 
          errors: {},
          message: {}
        });
        //const res = await axios.patch('/api/v1/account/profile', { ...this.state, headers: _token });
        const data = {
          title:title,
          firstName:firstName,
          lastName:lastName,
          languageCode:languageCode
        };
        const res = await apiRequest.account.updateProfile(data);
        if (res.status === 200) {
          //window.location.href = '/manage/profile?message=personalUpdated'
          this.props.updateEditVals(data);
          this.props.onCloseEdit(false, 'personalUpdated');
        }
      } catch (err) {
        console.log(err);
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              errors[key] = "errors:" + error.params[key][0];
            });
            this.setState({ errors: errors })
          }
        } else if (err.response.status === 403) {
          //403: Forbidden 
          //404: No such user
          this.setState({ message: { badRequestError: true } });
        } else if (err.response.status === 500) {
          this.setState({ message: { internalServerError: true } });
        }
      }
    // }
  }

  handleClose = () => {
    this.setState({
      errors: {
        badRequestError: false,
        internalServerError: false
      }
    });
  }

  handleCancel = () => {
    this.props.onCloseEdit();
  }

  renderGroupedList(list) {
    const groupedJobTitles = groupBy(list, 'groupName');
    return (
      groupedJobTitles.map((items, key) => {
        return <optgroup label={groupedJobTitles[key].key} key={groupedJobTitles[key].key}>
          {
            groupedJobTitles[key].items.map((item) => {
              return <option key={item.slug} value={item.code}>{item.name}</option>
            })
          }
        </optgroup>
      })
    )
  }

  renderParentList() {
    const { t } = this.props;
    const { industryParentList } = this.state;
    return (
      industryParentList.map((item) => {
        return <option key={item.key} value={item.key}>{t("group_name:" + item.key)}</option>
      })
    )
  }



  updateEditVal= (ev) => {
    if(ev.target.name==='industryCodeParent'){
      //console.log(ev.target.name, this.state.industryParentList);
      let item = this.state.industryParentList.find((o)=>{
        return (o.key===ev.target.value)
      })
      this.setState({industryCodeParent:ev.target.value, industryList:item.items, industryCode:item.items[0].code })
    }else{
      this.setState({
        [ev.target.name]: ev.target.value
      });
    }
  }

  render() {
    //this.updateList();
    return (this.state.edit === "company")?this.renderCompany():this.renderPersonal();
  }

  renderCompany() {
    const { t, classes } = this.props;
    const { errors, message, disableSubmit, companyName, jobTitle, industryCode, countryCode, industryCodeParent, industryList } = this.state;

    // page data
    const headerData = {
      header: t("profile:edit_company_info"),
      caption: t("profile:edit_company_info_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]
    //console.log(industryList);

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            {/* company name */}
            <Grid item xs={12}>
              <TextField value={companyName} onChange={this.updateEditVal} name="companyName" label={t("profile:company_name") + " *"} error={Boolean(errors["companyName"])} disabled={disableSubmit} />
              {(errors["companyName"]) && <ErrorMessage>{t("profile:company_name")}{t(errors["companyName"])}</ErrorMessage>}
            </Grid>

            {/* job title */}
            <Grid item xs={12} md={6}>
              <TextField value={jobTitle} onChange={this.updateEditVal} name="jobTitle" label={t("profile:job_title") + " *"} error={Boolean(errors["jobTitle"])} disabled={disableSubmit} />
              {(errors["jobTitle"]) && <ErrorMessage>{t("profile:job_title")}{t(errors["jobTitle"])}</ErrorMessage>}
            </Grid>

            {/* region */}
            <Grid item xs={12} md={6}>
              <Select value={countryCode} onChange={this.updateEditVal} name="countryCode" label={t("profile:region") + " *"} error={Boolean(errors["countryCode"])} disabled={disableSubmit}>
                <GatewayContext.Consumer>
                  {context => (
                    context.countries && Object.keys(context.countries).map(key => (
                      <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                    ))
                  )}
                </GatewayContext.Consumer>
              </Select>
              {(errors["countryCode"]) && <ErrorMessage>{t("common:region")}{t(errors["countryCode"])}</ErrorMessage>}
            </Grid>

            {/* organisation type */}
            <Grid item xs={12} sm={6}>
              <Select value={industryCodeParent} onChange={this.updateEditVal} name="industryCodeParent" label={t("common:organisation_category") + " *"} error={Boolean(errors["industryCode"])} disabled={disableSubmit}>
                <GatewayContext.Consumer>
                  {context => context.industryCodes && this.renderParentList()}
                </GatewayContext.Consumer>
              </Select>
              {(errors["industryCode"]) && <ErrorMessage>{t("common:organisation_category")}{t(errors["industryCode"])}</ErrorMessage>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select value={industryCode} onChange={this.updateEditVal} name="industryCode" label={t("profile:organisation_type") + " *"} error={Boolean(errors["industryCode"])} disabled={disableSubmit}>
                {!isEmpty(industryList) && 
                  industryList.sort((a, b) => a.code > b.code ? 1 : a.code === b.code ? 0 : -1).map((item) => {
                    return <option key={item.slug} value={item.code}>{t("industries:" + item.code)}</option>
                  })
                }
              </Select>
              {(errors["industryCode"]) && <ErrorMessage>{t("common:organisation_type")}{t(errors["industryCode"])}</ErrorMessage>}
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={[
          <Button color="secondary" fullWidth onClick={this.handleCancel} disabled={disableSubmit}>{t("common:cancel")}</Button>,
          <Button fullWidth onClick={this.handleCompanySubmit} disabled={disableSubmit} >{t("common:save")}</Button>
        ]} />
      </Container>
    )
  }

  renderPersonal() {
    const { t, classes } = this.props;
    const { errors, message, disableSubmit, firstName, lastName, languageCode, title, email} = this.state;

    // page data
    const headerData = {
      header: t("profile:edit_personal_info"),
      caption: t("profile:edit_personal_info_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            {/* title */}
            <Grid item xs={12} md={6}>
              <Select onChange={this.updateEditVal} id="title" name="title" label={t("profile:title") + " *"} value={title} error={Boolean(errors["title"])} disabled={disableSubmit}>
                <GatewayContext.Consumer>
                  {context => (
                    context.titles && Object.keys(context.titles).map(key => (
                      <option key={context.titles[key].slug} value={context.titles[key].slug}>{t("titles:" + context.titles[key].slug)}</option>
                    ))
                  )}
                </GatewayContext.Consumer>
              </Select>
              {(errors["title"]) && <ErrorMessage>{t("profile:title")}{t(errors["title"])}</ErrorMessage>}
            </Grid>

            <Grid item xs md={6} className={classes.empty} />

            {/* first name */}
            <Grid item xs={12} md={6}>
              <TextField value={firstName} onChange={this.updateEditVal} id="firstName" name="firstName" label={t("profile:first_name") + " *"} error={Boolean(errors["firstName"])} disabled={disableSubmit} />
              {(errors["firstName"]) && <ErrorMessage>{t("profile:firstname")}{t(errors["firstName"])}</ErrorMessage>}
            </Grid>

            {/* last name */}
            <Grid item xs={12} md={6}>
              <TextField value={lastName} onChange={this.updateEditVal} id="lastName" name="lastName" label={t("profile:last_name") + " *"} error={Boolean(errors["lastName"])} disabled={disableSubmit} />
              {(errors["lastName"]) && <ErrorMessage>{t("profile:lastname")}{t(errors["lastName"])}</ErrorMessage>}
            </Grid>

            {/* email */}
            <Grid item xs={12} md={6}>
              <TextField label={t("profile:email")} value={email} disabled={true} />
            </Grid>

            {/* language */}
            <Grid item xs={12} md={6}>
              <Select value={languageCode} onChange={this.updateEditVal}  name="languageCode" label={t("profile:email_language") + " *"} error={Boolean(errors["languageCode"])} disabled={disableSubmit}>
                <option value='en'>English</option>
                <option value='zh-CN'>简体中文</option>
                <option value='ja'>日本語</option>
              </Select>
              {(errors["languageCode"]) && <ErrorMessage>{t("profile:email_language")}{t(errors["languageCode"])}</ErrorMessage>}
            </Grid>
          </Grid>

          <Box component="header" style={{
            display: !process.env.REACT_APP__FEATURE__CHANGEMAIL_DISPLAY ? "none" : process.env.REACT_APP__FEATURE__CHANGEMAIL_DISPLAY
          }}>
            <Grid container>
              <Grid item xs={12} className={classes.captionItem}>
                  <PopupButton id="SFXK6Ahg" 
                    autoClose={3000}
                    className="MuiButton-textPrimary MuiLink-underlineHover MuiButtonBase-root"
                    size={40}
                    hidden={{ 
                      user_id: this.state.sub,
                      env: process.env.REACT_APP_ENVNAME
                     }}
                    style={{ fontSize: "0.8rem" }}>
                  ({t("common:Want to change email? Submit a request here")})
                  </PopupButton>
                </Grid>
            </Grid>
          </Box>

        </Box>

        <RenderFooter data={[
          <Button color="secondary" fullWidth onClick={this.handleCancel} disabled={disableSubmit}>{t("common:cancel")}</Button>,
          <Button fullWidth onClick={this.handleProfileSubmit} disabled={disableSubmit}>{t("common:save")}</Button>
        ]} />
      </Container>
    )
  }
}

EditInfo.contextType = GatewayContext;

export default withTranslation(['common', 'profile', 'errors'])(EditInfo);