import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import clsx from "clsx";
import { Grid, Card, CardContent, Box } from "@material-ui/core";
import { Info as InfoIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon, Cancel as CancelIcon, Help as HelpIcon } from '@material-ui/icons';
import { Typography } from "./Core";
import { RenderSubscription as useStyles } from "./Styles";


function RenderSubscription(props) {
  const { t, item } = props;
  const classes = useStyles(props);
  const statusType = {
    warning: ['PastDue', 'Incomplete', 'Inactive'],
    valid: ['Trialing', 'Active'],
    planning: ['NotStarted'],
    invalid: ['IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended'],
    error: ['Unknown']
  }

  const renderStatus = {
    PastDue: {
      iconLarge: <WarningIcon className={clsx(classes.icon, classes.warning)} />,
      iconSmall: <WarningIcon className={clsx(classes.boxIcon, classes.warning)} />,
      text: t('subscription:past_due'),
      color: "warning"
    },
    Incomplete: {
      iconLarge: <CancelIcon className={clsx(classes.icon, classes.warning)} />,
      iconSmall: <CancelIcon className={clsx(classes.boxIcon, classes.warning)} />,
      text: t('subscription:incomplete'),
      color: "warning"
    },
    Inactive: {
      iconLarge: <InfoIcon className={clsx(classes.icon, classes.info)} />,
      iconSmall: <InfoIcon className={clsx(classes.boxIcon, classes.info)} />,
      text: t('subscription:inactive'),
      color: "info"
    },
    Trialing: {
      iconLarge: <CheckCircleIcon className={clsx(classes.icon, classes.success)} />,
      iconSmall: <CheckCircleIcon className={clsx(classes.boxIcon, classes.success)} />,
      text: t('subscription:trialing'),
      color: "success"
    },
    Active: {
      iconLarge: <CheckCircleIcon className={clsx(classes.icon, classes.success)} />,
      iconSmall: <CheckCircleIcon className={clsx(classes.boxIcon, classes.success)} />,
      text: t('subscription:active'),
      color: "success"
    },
    NotStarted: {
      iconLarge: <InfoIcon className={clsx(classes.icon, classes.info)} />,
      iconSmall: <InfoIcon className={clsx(classes.boxIcon, classes.info)} />,
      text: t('subscription:not_started'),
      color: "info"
    },
    IncompleteExpired: {
      iconLarge: <CancelIcon className={clsx(classes.icon, classes.error)} />,
      iconSmall: <CancelIcon className={clsx(classes.boxIcon, classes.error)} />,
      text: t('subscription:invalid'),
      color: "error"
    },
    UnPaid: {
      iconLarge: <CancelIcon className={clsx(classes.icon, classes.error)} />,
      iconSmall: <CancelIcon className={clsx(classes.boxIcon, classes.error)} />,
      text: t('subscription:unpaid'),
      color: "error"
    },
    Canceled: {
      iconLarge: <CancelIcon className={clsx(classes.icon, classes.error)} />,
      iconSmall: <CancelIcon className={clsx(classes.boxIcon, classes.error)} />,
      text: t('subscription:canceled'),
      color: "error"
    },
    Suspended: {
      iconLarge: <CancelIcon className={clsx(classes.icon, classes.error)} />,
      iconSmall: <CancelIcon className={clsx(classes.boxIcon, classes.error)} />,
      text: t('subscription:suspended'),
      color: "error"
    },
    Unknown: {
      iconLarge: <HelpIcon className={clsx(classes.icon, classes.error)} />,
      iconSmall: <HelpIcon className={clsx(classes.boxIcon, classes.error)} />,
      text: t('subscription:unknown'),
      color: "error"
    }
  }
  let isCorp = (window.location.href.indexOf("corporate")>-1)?true:false;
  let path = (isCorp)?`/manage/corporate/`:`/manage/subscriptions/`;

  return (
    <Grid item xs={12} className={classes.item}>
      <Card elevation={0} component={Link} to={`${path}${item.id}`} className={classes.card}>
        <CardContent className={classes.content}>
          {renderStatus[item.status].iconLarge}
        </CardContent>
        <CardContent className={classes.content}>
                  <Typography variant="h6" className={classes.title}>{item.productName} {(item.planName !== undefined && item.planName !== null && item.planName !== "") ? ` - ${item.planName}` : ""}</Typography>

          {item.status === "Incomplete" && item.createdAt &&
            <Typography variant="body1" className={classes.content}>{t('subscription:creation_date')}: {moment(item.createdAt).format("ll")}</Typography>
          }

          {item.status === "Inactive" && item.currentPeriodStart &&
            <Typography variant="body1" className={classes.content}>{t('subscription:current_period_start')}: {moment(item.currentPeriodStart).format("ll")}</Typography>
          }

          {item.status === "PastDue" && item.currentPeriodStart &&
            <Typography variant="body1" className={classes.content}>{t('subscription:renewal_date')} : {moment(item.currentPeriodStart).format("ll")}</Typography>
          }

          {statusType.valid.indexOf(item.status) !== -1 && !isCorp && !item.cancelAtPeriodEnd &&
            <Typography variant="body1" className={classes.content}>
              {t('subscription:renewal_date')} : {moment(item.currentPeriodEnd).format("ll")}
            </Typography>
          }

          {statusType.valid.indexOf(item.status) !== -1 && !isCorp && item.cancelAtPeriodEnd &&
            <Typography variant="body1" className={classes.content}>
              {t('subscription:end_date')} : {moment(item.currentPeriodEnd).format("ll")}
            </Typography>
          }

          {statusType.valid.indexOf(item.status) !== -1 && isCorp &&
            <Typography variant="body1" className={classes.content}>
              {t('subscription:end_date')} : {moment(item.cancelAt).format("ll")}
            </Typography>
          }

          {statusType.planning.indexOf(item.status) !== -1 &&
            <Typography variant="body1" className={classes.content}>
              {t('subscription:start_date')} : {moment(item.currentPeriodStart).format("ll")}
            </Typography>
          }

          {statusType.invalid.indexOf(item.status) !== -1 && 
            <Typography variant="body1" className={classes.content}>
              {t('subscription:end_date')}: {!item.cancelAtPeriodEnd ? moment(item.currentPeriodStart).format("ll") : moment(item.currentPeriodEnd).format("ll")}
            </Typography>
          }

          <Box className={classes.box}>
            {renderStatus[item.status].iconSmall}

            <Typography variant="body1" color={renderStatus[item.status].color}>{renderStatus[item.status].text}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withTranslation(["common", "subscription"])(RenderSubscription);