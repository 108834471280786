import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { isEmpty} from "../../utils/Utils";
import apiRequest from "../../utils/apiRequest";
import { Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import { YourSubscriptions as useStyles } from "./Styles";
import { RenderSubscription, RenderSectionTitle, IconLabelButton } from '../UI/Core';
import JoinSubscriptions from './JoinSubscriptions';

class YourSubscriptions extends Component {
  static displayName = YourSubscriptions.name;

  constructor(props) {
    super(props);
    this.state = {
      plans: {},
      status: this.props.status || ['Trialing', 'Active'],
      subscriptions: [],
      subsPaging: {},
      items: 8,
      message: {},
      token: ''
    }
    this.handleClose = this.handleClose.bind(this);
    this.getMore = this.getMore.bind(this);
  }


  async getMore() {
    const { status, items, subsPaging} = this.state;
    let { subscriptions } = this.state;
    const { cursor} = subsPaging;
    try {
      let res = await apiRequest.subscription.list({ limit: items,cursor:cursor, status: status });
      if (res.status === 200) {
        let newItems = subscriptions.concat(res.data.results);
        this.setState({subscriptions:newItems, subsPaging:res.data.paging});
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }

  async componentDidMount() {

    const {status, items} = this.state;
    try {
      let res = await apiRequest.subscription.list({ limit: items, status: status });
      this.setState({subscriptions:res.data.results, subsPaging:res.data.paging});
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }

    }
  }

  handleClose() {
    this.setState({
      message: {}
    });
  }

  render() {
    const { t, classes, reminder, subscriptionAlert, title } = this.props;
    const { subscriptions, subsPaging } = this.state;


    // page data
    // const messageData = [
    //   {
    //     open: message.badRequestError,
    //     onClose: this.handleClose,
    //     severity: "error",
    //     title: t("errors:bad_request_error"),
    //     children: t("errors:bad_request_error_content")
    //   },
    //   {
    //     open: message.internalServerError,
    //     onClose: this.handleClose,
    //     severity: "error",
    //     title: t("errors:internal_server_error"),
    //     children: t("errors:internal_server_error_content")
    //   }
    // ]
    //console.log(plans, subscriptions)

    return (
      !isEmpty(subscriptions) ? 
        <Box component="section" className={classes.sectionRoot}>
          <RenderSectionTitle data={title} />
          <Grid container className={classes.gridContainer}>
            {!isEmpty(subscriptions)  ? 
              <Fragment>
                {subscriptions.map((item, key) => <RenderSubscription key={key} item={item} />)}
                {subsPaging.hasMore ?
                  <Grid item xs={12} className={classes.item}>
                    <Box textAlign="center">
                      <IconLabelButton key={subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={(e) => this.getMore()}>{t("common:show_more")}</IconLabelButton>
                    </Box>
                  </Grid>
                  : !reminder && <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid>
                }
              </Fragment>
              : !reminder && <Grid item xs={12} className={classes.item}>{t("subscription:no_subscription")}</Grid>
            }
          </Grid>
        </Box>
      : subscriptionAlert ? <JoinSubscriptions />
       : null
    )
  }
}

export default withTranslation(["common", "subscriotion"])(withStyles(useStyles)(YourSubscriptions));
