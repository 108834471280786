import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { InvalidEmail as useStyles } from "./Styles";
import { RenderHeader, RenderFooter, Typography } from "../UI/Core";

class InvalidEmail extends Component {
  static displayName = InvalidEmail.name;

  constructor(props) {
    super(props);
    this.state = {
      footerData: this.props.footerData
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ error: false })
  }

  render() {
    const { t, classes } = this.props;
    const { footerData } = this.state;
    const theme = "signup";

    const headerData = {
      header: t("errors:email_invalid"),
      theme: theme
    }

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <Container maxWidth="xs" className={classes.root}>
        <RenderHeader {...headerData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <Typography dangerouslySetInnerHTML={{ __html: t("errors:email_invalid_content") }} variant="body1" component="div" className={classes.typography} />
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />
      </Container>
    )
  }
}

InvalidEmail.propTypes = {
  footerData: PropTypes.array.isRequired
}

export default withTranslation(["common", "errors"])(withStyles(useStyles)(InvalidEmail));