import React from "react";
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from "@material-ui/core";
import { RenderDisplaySection as useStyles } from "./Styles";

RenderDisplaySection.propTypes = {
  data: PropTypes.array.isRequired
}

export default function RenderDisplaySection(props) {
  const { data, t } = props;
  const classes = useStyles(props);

  return data ? (
    <Box component="section" className={classes.root}>
      {data.map((item, key) => (
        item && (
          <Box key={key} className={classes.container}>
            <Grid container className={classes.row}>
              <Grid item xs className={classes.titleItem}>
                <Typography variant="body1" className={classes.title}>{item.title}</Typography>
              </Grid>
              <Grid item xs className={classes.valueItem}>
                {item.valueBase ? item.valueBase
                  : item.value && <Typography variant="body1" color={item.color || undefined} className={classes.value}>{(item.value)?item.value:t('payment:no_payment_method')}</Typography>}
              </Grid>
            </Grid>
          </Box>
        )
      ))}
    </Box>
  ) : null
}