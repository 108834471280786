import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { GatewayContext } from "../../utils/GatewayContext";

import { Container, Grid, Box, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Dashboard as useStyles } from "./Styles";
import YourSubscriptions from "./YourSubscriptions";
import { RenderHeader, RenderSectionTitle } from "../UI/Core";
import clsx from "clsx";
import links from "../../links/links.json";

class Dashboard extends Component {
  static displayName = Dashboard.name;

  renderLinks = () => {
    const { classes } = this.props;
    return (
      links.map((item, key) =>
        <Grid item key={key} className={classes.item}><a href={item.url} target="_blank" rel="noopener noreferrer"><img src={item.logo} className={clsx(classes.logo, classes[item.class])} alt={item.name} /></a></Grid>
      )
    )
  }
  render() {
    const { t, classes } = this.props;

    // page data
    const headerData = {
      header: t('dashboard:dashboard')
    }

    return (
      <Container maxWidth="md" className={classes.root}>
        <RenderHeader {...headerData} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <YourSubscriptions title={t('subscription:subscription_reminder')} status={['PastDue', 'Incomplete', 'Inactive']} reminder small />
            <YourSubscriptions title={t('subscription:valid_subscriptions')} status={['Trialing', 'Active']} subscriptionAlert />
            <Box component="section" className={classes.sectionRoot}>
              <RenderSectionTitle data={t('dashboard:our_brands')} />
              <Grid container alignItems="center" className={classes.brandGridContainer}>
                {this.renderLinks()}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withTranslation(['common', 'dashboard', 'subscription'])(withWidth()(withStyles(useStyles)(Dashboard)));
