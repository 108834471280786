import React, { Component } from 'react';
export default class PhonePrefix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: this.props.countries,
      selected: "",
      phonePrefix: ""
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selected !== state.selected) {
      const country = state.countries.find(o => o.code === props.selected);
      let prefix = "";
      if (country) {
        props.onPrefixChange({ countryCode:country.code, phonePrefix: country.callPrefix, key: props.name });
        prefix = country.callPrefix;
      }
      return { selected: props.selected, phonePrefix: prefix };
    }
    return null;
  }

  render() {
    return <span>{"+( " + this.state.phonePrefix + " )"}</span>
  }
}

