import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Container, Box, Grid } from "@material-ui/core";
import Header from "./Header";
import AuthorizeFooter from "./AuthorizeFooter";
import Menu from "./Menu";
import { AuthorizeLayout as useStyles } from "./Styles";

function AuthorizeLayout(props) {
  const { i18n, ...rest } = props;

  return (
    <Box className={i18n.language}>
      <Header />
      <Content {...rest} />
      <AuthorizeFooter />
    </Box>
  )
}

function Content(props) {
  const classes = useStyles(props);

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Grid container className={classes.container}>
        <Menu/>
        <Grid data-cy='main' item className={classes.content}>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  )
}

export default withTranslation()(withRouter(AuthorizeLayout));