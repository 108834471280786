import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { Container, Box, Grid } from "@material-ui/core";
import { Error404 as useStyles } from "./Styles";
import { RenderHeader, RenderFooter, Typography, Button } from "../UI/Core";

export default function Error404(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);

  const theme = "signup";

  // page data
  const headerData = {
    header: t("errors:404"),
    theme: theme
  }

  const footerData = [
    <Button component={Link} to={ApplicationPaths.DefaultLoginRedirectPath} fullWidth>{t("common:home")}</Button>
  ]

  document.title = (headerData.header + " - " + t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

  return (
    <Container maxWidth="xs" className={classes.root}>
      <RenderHeader {...headerData} />

      <Box component="section" className={classes.sectionRoot}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs>
            <Typography dangerouslySetInnerHTML={{ __html: t("errors:404_content") }} variant="body1" component="div" />
          </Grid>
        </Grid>
      </Box>

      <RenderFooter data={footerData} theme={theme} />
    </Container>
  )
}