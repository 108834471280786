import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export default function (props) {
  const { classes, margin, fullWidth, ...rest } = props;

  return (
    <FormControl
      variant="outlined"
      margin={margin}
      fullWidth={fullWidth === false ? false : true}
    >
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        native
        {...rest}
        classes={classes}
      >{props.children}</Select>
    </FormControl>
  )
}