import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Snackbar from "./Snackbar";

RenderMessage.propTypes = {
  data: PropTypes.array.isRequired
}

export default function RenderMessage(props) {
  const { data } = props;

  return data ? (
    <Fragment>
      {data.map((item, key) => (
        <Snackbar
          key={key}
          open={item.open}
          onClose={item.onClose}
          severity={item.severity}
          title={item.title}
          children={item.children}
        />
      ))}
    </Fragment>
  ) : null
}