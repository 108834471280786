import React from "react";
import PropTypes from 'prop-types';
import { Grid, Typography } from "@material-ui/core";
import { RenderSectionTitleWithFeature as useStyles } from "./Styles";

RenderSectionTitleWithFeature.propTypes = {
  data: PropTypes.object.isRequired
}

export default function RenderSectionTitleWithFeature(props) {
  const { data } = props;
  const classes = useStyles(props);

  return data ? (
    <Grid container component="header" className={classes.root}>
      <Grid item xs className={classes.titleItem}>
        <Typography variant="body1" className={classes.header}>{data.header}</Typography>
      </Grid>
      <Grid item xs className={classes.valueItem}>
        {data.feature}
      </Grid>
    </Grid>
  ) : null
}