import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import apiRequest from "../../utils/apiRequest";
import authService from '../api-authorization/AuthorizeService';
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import Language from "./Language";
import { ReactComponent as Logo } from "../../images/haymarket.svg";
import { AppBar, Toolbar, Box, Typography, Link as ALink } from "@material-ui/core";
import { Header as useStyles } from "./Styles";
import { RenderDialog } from "../UI/Core";
import queryString from "query-string";

export default function Header(props) {
  const {t} = useTranslation(['common']);
  const classes = useStyles(props);
  const [email, setEmail] = useState("");
  const [signoutOpen, setSignoutOpen] = useState(false);
  const [isLogined, setIsLogined] = useState(false);

  useEffect(() => {
    let isAuth = false;
    const params = queryString.parse(window.location.search);
    const populateState = async() => {
      const isAuth = await authService.isAuthenticated();
      setIsLogined(isAuth);
      isAuth && getAccountEmail();
    }

    const getAccountEmail = async() => {
      try {
        const profile = await apiRequest.account.profile(params.src);
        setEmail(profile.data.result.email);
      } catch (err) {
        if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
          //403: Forbidden 
          //404: No such user
          console.log("something went wrong loading", err)
        } else if (err.response.status === 500) {
          console.log("something went wrong loading", err)
        }
      }
    }
    populateState();
  }, [])

  const dialogHandler = (e)=>{
    e.preventDefault();
    setSignoutOpen(!signoutOpen);
  }

  const dialogData = [
    {
      open: signoutOpen,
      onClose: dialogHandler,
      link: { pathname: "/authentication/logout", state: { local: true } },
      title: t("common:confirm_logout"),
      children: t("common:confirm_logout_content"),
      cancel: true
    }
  ]

  return (
    <>
      <AppBar data-cy="appbar" component="header" position="sticky" elevation={0} className={classes.root}>
        <Toolbar>
          <Link to={"/"}><Logo className={classes.logo} /></Link>
          <Box className={classes.spacing} />
          {isLogined &&
            <>
              <Typography className={classes.email} alt={email}>{email}</Typography>
              <Box mr={1} fontSize={12}>
                ( <ALink href="#" onClick={dialogHandler}>{t("common:not_you")}?</ALink> )
              </Box>
            </>
          }
          <Language />
          <Menu drawer />
        </Toolbar>
      </AppBar>
      <RenderDialog data={dialogData} />
    </>
  )
}
