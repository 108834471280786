import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { Grid, Button, Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { Dialog as Styles } from "./Styles";

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(["success", "warning", "info", "error"]),
  cancel: PropTypes.bool,
  close: PropTypes.bool
};

function Dialog(props) {
  const { t, open, onClose, onConfirm, link, title, content, severity, cancel } = props;
  const classes = Styles(props);

  return (
    <MuiDialog
      onClose={onClose}
      open={Boolean(open)}
      className={classes.root}
      classes={{
        paper: clsx(
          classes.paper,
          (severity === "success") && classes.success,
          (severity === "warning") && classes.warning,
          (severity === "info") && classes.info,
          (severity === "error") && classes.error
        )
      }}
    >
      {title &&
        <DialogTitle className={clsx(classes.title, severity && classes.titleSeverity)}>{title}</DialogTitle>}
      <DialogContent className={classes.content}>
        <DialogContentText className={clsx(classes.text, severity && classes.textSeverity)}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Grid container className={classes.container}>
          <Grid item xs className={classes.item}>
            <Button
              onClick={onClose}
              color="primary"
              autoFocus={!(Boolean(onConfirm))}
              variant={severity && "contained"}
              disableElevation
              fullWidth
              className={
                clsx(
                  classes.button,
                  severity && classes.buttonSeverity,
                )
              }
            >
              {(onConfirm || cancel) ? t("common:cancel") : t("common:close")}
            </Button>
          </Grid>
          {onConfirm &&
            <Grid item xs className={classes.item}>
              <Button
                onClick={onConfirm}
                color="primary"
                autoFocus
                variant={severity && "contained"}
                disableElevation
                fullWidth
                className={
                  clsx(
                    classes.button,
                    severity && classes.buttonSeverity,
                  )
                }
              >
                {t("common:confirm")}
              </Button>
            </Grid>
          }
          {link &&
            <Grid item xs className={classes.item}>
              <Button
                component={Link}
                to={link}
                color="primary"
                autoFocus
                variant={severity && "contained"}
                disableElevation
                fullWidth
                className={
                  clsx(
                    classes.button,
                    severity && classes.buttonSeverity,
                  )
                }
              >
                {t("common:confirm")}
              </Button>
            </Grid>}
        </Grid>
      </DialogActions>
    </MuiDialog>
  );
}

export default withTranslation(["common"])(Dialog);