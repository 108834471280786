import theme, { defaultTheme } from "../../themes/theme";
import ColumnGrid from "../../themes/ColumnGrid";
import { fade } from "@material-ui/core/styles/colorManipulator";

const ShareStyles = theme => {
  const { root, margin, spacing } = defaultTheme;

  return {
    root: {
      margin: theme.spacing(root.top, 0, root.bottom)
    },
    sectionRoot: {
      margin: theme.spacing(margin, 0)
    },
    gridContainer: {
      ...ColumnGrid({ theme: theme, argument: spacing })
    },
    title: {
      fontWeight: "700"
    },
    body: {
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h5.fontSize,
      }
    },
    end: {
      textAlign: "right"
    },
    currency: {
      textTransform: "uppercase"
    },
    remark: {
      fontSize: "0.75rem"
    },
    overlay: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 1101,
      backgroundColor: fade("#000", 0.5)
    },
    link: {
      color: theme.palette.text.primary,
  
      '&:hover': {
        color: theme.palette.action.hover
      }
    }
  }
}

export const SubscriptionSteps = theme => ({
  root: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    flexFlow: "column nowrap",

    [theme.breakpoints.up(0) + " and (orientation: landscape)"]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"].minHeight}px)`,
    },

    [theme.breakpoints.up("sm")]: {
      // flexFlow: "row wrap",
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    },

    [theme.breakpoints.only("sm") + " and (orientation: landscape)"]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px) and (max-width:959px) and (orientation: landscape)"].minHeight}px)`,
    }
  },
  sideBar: {
    width: "100%",
    // padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    color: "white",
    backgroundColor: (theme.palette.type === "light") ? theme.palette.grey["300"] : theme.palette.grey["900"],
    overflow: "auto",

    // [theme.breakpoints.up("sm")]: {
    //   width: "30%",
    //   maxWidth: 256
    // }
  },
  content: {
    maxWidth: "100%",
    flexGrow: "1",
    flexBasis: 0
  }
});

export const Steps = theme => ({
  root: {
    margin: 0,
    minWidth: 480
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),

    // [theme.breakpoints.up("sm")]: {
    //   paddingTop: theme.spacing(4),
    //   paddingBottom: theme.spacing(3)
    // }
  },
  stepper: {
    backgroundColor: "transparent"
  },
  // label: {
  //   color: fade(theme.palette.common.white, 0.5)
  // },
  active: {
    color: (theme.palette.type === "light") ? undefined : `${theme.palette.primary.main} !important`
  },
  completed: {
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
      transition: "color 0.2s ease",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  iconRoot: {
    color: (theme.palette.type === "light") ? theme.palette.secondary.light : undefined
  },
  iconActive: {
    "& .MuiStepIcon-text": {
      fontWeight: "bold",
      fill: theme.palette.secondary.main
    }
  },
  label: {
    textTransform: "capitalize"
  }
  // grid: {
  //   margin: `${theme.spacing(-0.5)}px 0`,

  //   [theme.breakpoints.up("sm")]: {
  //     margin: `${theme.spacing(-2)}px 0`,
  //   },

  //   "& .MuiGrid-item": {
  //     padding: theme.spacing(0.5, 3),

  //     [theme.breakpoints.up("sm")]: {
  //       padding: theme.spacing(2, 3)
  //     }
  //   }
  // },
  // text: {
  //   color: theme.palette.common.white
  // },
  // highlight: {
  //   backgroundColor: theme.palette.secondary.light,

  //   "& p": {
  //     fontWeight: "700 !important"
  //   }
  // }
});

export const Subscription = theme => ({
  ...ShareStyles(theme),
  button: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    color: theme.palette.text.secondary,
    textAlign: "left",

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3)
    },

    "&:hover": {
      color: theme.palette.text.primary
    }
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.primary.main, 0.25)
  },
  body: {
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h5.fontSize,
    }
  },
  end: {
    textAlign: "right"
  },
  currency: {
    textTransform: "uppercase"
  }
})

export const Address = theme => ({
  ...ShareStyles(theme)
})

export const SelectPayment = theme => ({
  ...ShareStyles(theme),
})

export const AddPaymentForm = theme => ({
  ...ShareStyles(theme),
  cardElement: {
    padding: "17.5px 13px",
    border: `solid 1px ${theme.palette.border.primary}`,
    borderRadius: 4,

    "&.StripeElement--focus": {
      padding: "16.5px 12px",
      borderWidth: 2,
      borderColor: theme.palette.primary.main
    },

    "&.StripeElement--invalid": {
      borderColor: theme.palette.error.main
    }
  },
  cardElementError: {
    borderColor: theme.palette.error.main
  }
})

export const StripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap'
    }
  ]
}

export const CardStyle = {
  base: {
    color: theme.palette.text.primary,
    iconColor: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSmoothing: "antialiased",
    fontSize: theme.typography.htmlFontSize,

    "::placeholder": {
      color: theme.palette.text.secondary
    },
  },
  focus: {
    color: fade(theme.palette.primary.main, 0.87),

    "::placeholder": {
      color: theme.palette.primary.main
    },
  },
  invalid: {
    color: theme.palette.error.main,
    iconColor: theme.palette.error.main
  }
}

export const Checkout = theme => ({
  ...ShareStyles(theme)
})