import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { Container, Box, Grid} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ResetPassword as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderFooter, RenderMessage, Typography, TextField, ErrorMessage, Button } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";

class ResetPassword extends Component {
  static displayName = ResetPassword.name;

  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    this.recaptchaRef = React.createRef();
    this.state = {
      email: "",
      password: "",
      newPassword: "",
      token: params.token || null,
      returnurl: params.returnurl || '',
      status: params.status || null,
      confirmNewPassword: "",
      RecaptchaResponse: '',
      errors: {},
      message: {},
      success: false,
      disableSubmit: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    const {token, status} = this.state;
    
    if (!token || token==='' || status!=='success')
      this.setState({ message: "reset_token_is_expired" })
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    // if (!this.handleValidation()) {
      try {
        this.recaptchaRef.current.reset();
        const token = await this.recaptchaRef.current.executeAsync();
        //console.log('here', token);
        this.setState({
          disableSubmit: true,
          errors: {},
          message: {},
          RecaptchaResponse: token
        });
        const signup_resp = await apiRequest.account.password(true, this.state);
        if (signup_resp.status === 200) {
          this.setState({
            success: true,
            RecaptchaResponse: '',
            disableSubmit: false
          });
        }
      } catch (err) {
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              if (key === "newPassword") {
                errors[key] = [];
                error.params[key].forEach(function (item, i) {
                  errors[key][i] = "errors:" + item;
                })
              } else if (key === "token") {
                errors[key] = error.params[key][0];
              } else {
                errors[key] = "errors:" + error.params[key][0];
              }
            });
            if (error.params.token) {
              this.setState({
                errors: errors,
                RecaptchaResponse: '',
                message: { tokenInvalid: true }
              });
            } else {
              this.setState({
                errors: errors,
                RecaptchaResponse: ''
              });
            }
          }
          else {
            this.setState({
              RecaptchaResponse: '',
              message: { badRequestError: true }
            });
          }
        }
        else if (err.response.status === 500) {
          this.setState({
            RecaptchaResponse: '',
            message: { internalServerError: true }
          });
        }
      }
    // }
  }

  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }


  handleClose() {
    this.setState({ message: {} })
  }

  renderCompleted() {
    const { t, classes } = this.props;
    const loginPath = ApplicationPaths.Login;
    const theme = "signup";
    const parsed = queryString.parse(window.location.search);

    // page data
    const headerData = {
      header: t("password:password_reset_completed"),
      theme: theme
    }

    const footerData = [
      (parsed.returnurl || parsed.ReturnUrl || parsed.returnUrl) ? <Button component={'a'} href={(parsed.returnurl || parsed.ReturnUrl || parsed.returnUrl)} fullWidth>{t("common:next")}</Button>
      : <Button component={Link} to={loginPath} fullWidth>{t("common:signin")}</Button>
    ]

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return (
      <Container maxWidth="xs" className={classes.root}>
        <RenderHeader {...headerData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <Typography dangerouslySetInnerHTML={{ __html: t("password:password_reset_completed_content") }} variant="body1" component="div" />
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />
      </Container>
    )
  }

  renderResetPassword() {
    const { t, classes } = this.props;
    const { errors, message, newPassword, confirmNewPassword, email, disableSubmit } = this.state;
    const theme = "signup";

    // page data
    const headerData = {
      header: t("password:reset_password"),
      caption: t("password:reset_password_caption"),
      theme: theme
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.tokenInvalid,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:reset_token_invalid"),
        children: t("errors:reset_token_invalid_content")
      }
    ]

    const footerData = [
      <Button fullWidth onClick={this.handleSubmit} disabled={disableSubmit}>{t("common:submit")}</Button>
    ]

    document.title = (headerData.header + " - " + this.props.t("common:haymarket_account")).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    //console.log(disableSubmit);
    return (
      <Container maxWidth="xs" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <TextField value={email} onChange={this.handleInputChange} id="email" name="email" label={t("profile:email") + " *"} error={Boolean(errors["email"])} disabled={disableSubmit} />
              {(errors["email"]) && <ErrorMessage>{t("profile:email")}{t(errors["email"])}</ErrorMessage>}
            </Grid>
            <Grid item xs={12}>
              <TextField value={newPassword} onChange={this.handleInputChange} id="newPassword" name="newPassword" type="password" label={t("password:new_password") + " *"} error={Boolean(errors["newPassword"])} disabled={disableSubmit} />
              {(errors["newPassword"]) && errors["newPassword"].map((item, idx) => <ErrorMessage key={idx}>{t("password:password")}{t(item)}</ErrorMessage>)}
            </Grid>

            <Grid item xs={12}>
              <TextField value={confirmNewPassword} onChange={this.handleInputChange} id="confirmNewPassword" name="confirmNewPassword" type="password" label={t("password:confirm_password") + " *"} error={Boolean(errors["confirmNewPassword"])} disabled={disableSubmit} />
              {(errors["confirmNewPassword"]) && <ErrorMessage>{t("password:confirm_password")}{t(errors["confirmNewPassword"])}</ErrorMessage>}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" align="left" className={classes.remarks}>{t("password:password_requirement")}</Typography>
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} theme={theme} />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          ref={this.recaptchaRef}
          size="invisible"
        />
         
      </Container >
    )
  }

  render() {
    const { success } = this.state;

    if (success) {
      return this.renderCompleted();
    } else {
      return this.renderResetPassword();
    }
  }
}

export default withTranslation(["common", "errors", "password","profile"])(withStyles(useStyles)(ResetPassword));