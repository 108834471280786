import React, { Component, Fragment } from 'react'
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { Link } from "react-router-dom";
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import { withTranslation } from "react-i18next";
import { getLanguageCode } from "../../utils/Utils";
import { Backdrop, Message } from "./../../components/UI/Core";
import { Button } from "../UI/Core";
import queryString from 'query-string';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
class LogoutComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false,
            curr_lang: getLanguageCode(queryString.parse(window.location.search).lang) || getLanguageCode(localStorage.getItem("lang")) || getLanguageCode((navigator.language || navigator.userLanguage).toLowerCase())
        };
    }

    componentDidMount() {
        const action = this.props.action;
        const t = this.props.t;
        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state.state.local) {
                    this.logout(this.getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    this.setState({
                        isReady: true,
                        message:
                            <Message
                                message={t("common:was_not_logout")}
                                buttons={[
                                    <Button component={Link} to={ApplicationPaths.DefaultLoginRedirectPath} fullWidth>{t("common:home")}</Button>,
                                    <Button color="secondary" component={Link} to={ApplicationPaths.Login} fullWidth>{t("common:signin")}</Button>
                                ]}
                            />
                    });
                }
                break;
            case LogoutActions.LogoutCallback:
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                this.setState({
                    isReady: true,
                    message:
                        <Message
                            message={t("common:successful_logout")}
                            buttons={[
                                <Button component={Link} to={ApplicationPaths.DefaultLoginRedirectPath} fullWidth>{t("common:home")}</Button>
                            ]}
                        />
                });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        this.populateAuthenticationState();
    }

    render() {
        const { t, i18n } = this.props;
        const { curr_lang } = this.state;
        if (i18n.language !== curr_lang) { i18n.changeLanguage(curr_lang, err => err && console.log('something went wrong loading', err)) }

        const { isReady, message } = this.state;
        if (!isReady) {
            return <div></div>
        }
        if (!!message) {
            return (<Fragment>{message}</Fragment>);
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return (
                        <Backdrop message={t("common:processing_logout")} />
                    );
                case LogoutActions.LogoutCallback:
                    return (
                        <Backdrop message={t("common:processing_logout_callback")} />
                    );
                case LogoutActions.LoggedOut:
                    return (<Fragment>{message}</Fragment>);
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

    async logout(returnUrl) {
        const t = this.props.t;
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    await authService.userManager.clearStaleState();
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: <Message message={result.message} /> });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            this.setState({
                message:
                    <Message
                        message={t("common:successful_logout")}
                        buttons={[
                            <Button component={Link} to={ApplicationPaths.DefaultLoginRedirectPath} fullWidth>{t("common:home")}</Button>
                        ]}
                    // transMessage="common:successful_logout"
                    // primaryButtonTransText="common:home"
                    // primaryButtonLink={ApplicationPaths.DefaultLoginRedirectPath}
                    />
            });
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: <Message message={result.message} /> });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        return window.location.replace(returnUrl);
    }
}

export const Logout = withTranslation(["common"])(LogoutComponent);
