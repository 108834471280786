import React, { Component, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { isEmpty } from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import { Subscriptions as useStyles } from "./Styles";
import { RenderHeader, RenderMessage, RenderFeatureButton, RenderSectionTitle, RenderSubscription, RenderTenant, RenderTenantSm, IconLabelButton, TextField, Select } from "../UI/Core";
import apiRequest from "../../utils/apiRequest";
import { Subscription } from '../Payment/Styles';


class Corporate extends Component {
  static displayName = Corporate.name;

  constructor(props) {
    super(props);
    //let isBulk = (window.location.href.indexOf("bulksubscription"))?true:false;
    this.state = {
      parentPath: "/manage/corporate",
      message: {},
      token: {},
      plans: [],
      planPaging: {},
      types: ['search', 'warning', 'valid', 'invalid', 'myTenants'],
      keywords: '',
      searchStatus: '',
      searchResult: {
        status: ['Trialing', 'Active', 'NotStarted', 'PastDue', 'Incomplete', 'Inactive', 'IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended'],
        subscriptions: [],
        subsPaging: {},
      },
      searchTimeoutId: -1,
      search: {
        status: ['Trialing', 'Active', 'NotStarted', 'PastDue', 'Incomplete', 'Inactive', 'IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended'],
        subscriptions: [],
        subsPaging: {},
      },
      warning: {
        status: ['PastDue', 'Incomplete', 'Inactive'],
        subscriptions: [],
        subsPaging: {},
      },
      valid: {
        status: ['Trialing', 'Active', 'NotStarted'],
        subscriptions: [],
        subsPaging: {},
      },
      invalid: {
        status: ['IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended'],
        subscriptions: [],
        subsPaging: {},
      },
      myTenants: {
        subscriptions: [],
        subsPaging: {}
      },
      items: 100,
      plansItems: 50,
      focus: {}
    }
    // subscription status
    // trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid
    //this.getPlans = this.getPlans.bind(this);
    this.getMore = this.getMore.bind(this);
    this.handleShowExpired = this.handleShowExpired.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  async componentDidMount() {
    const { items, search, warning, valid, invalid } = this.state;
    try {
      /*    const token = await tokenHeader();
          this.setState({ token: token });*/
      //this.getPlans(token);
      let valids, invalids, warnings, searchs;

      valids = await apiRequest.corporate.list({ limit: items, status: valid.status });
      invalids = await apiRequest.corporate.list({ limit: items, status: invalid.status });
      warnings = await apiRequest.corporate.list({ limit: items, status: warning.status });
      searchs = await apiRequest.corporate.list({ limit: 1000, status: search.status });

      const myTenants = await apiRequest.tenants.myTenants({ limit: items });

      // console.log(searchs.data);
      // console.log(warnings.data);
      // console.log(valids.data);
      // console.log(invalids.data);
      // console.log(myTenants.data);

      this.setState({
        mount: true,
        search: {
          ...this.state.search,
          subscriptions: searchs?.data?.results,
          subsPaging: searchs?.data?.paging,
        },
        warning: {
          ...this.state.warning,
          subscriptions: warnings?.data?.results,
          subsPaging: warnings?.data?.paging,
        },
        valid: {
          ...this.state.valid,
          subscriptions: valids?.data?.results,
          subsPaging: valids?.data?.paging,
        },
        invalid: {
          ...this.state.invalid,
          subscriptions: invalids?.data?.results,
          subsPaging: invalids?.data?.paging,
        },
        myTenants: {
          ...this.state.myTenants,
          subscriptions: myTenants.data.results,
          subsPaging: myTenants.data.paging,
        }
      })
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({ message: { badRequestError: true } })
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } })
      }
    }
    /*    this.getSubscriptions(token, "valid");
      this.getSubscriptions(token, "invalid");*/
  }

  async componentDidUpdate(prevProps, prevState) {
    
  }

  async getMore(type, isTenantApiCall) {
    const { types, items } = this.state;
    const { status, subscriptions, subsPaging } = this.state[types.indexOf(type) !== -1 ? type : "valid"];
    //console.debug('subsPaging', subsPaging)
    try {
      const res = isTenantApiCall ? await apiRequest.tenants.myTenants({ limit: (type == 'search' ? 1000 : items), cursor: subsPaging.cursor })
        : await apiRequest.corporate.list({ limit: items, status: status, cursor: subsPaging.cursor });

      if (res.status === 200) {
        let newItems = subscriptions.concat(res.data.results);
        this.setState({
          [type]: {
            status: status,
            subscriptions: newItems,
            subsPaging: res.data.paging
          }
        });
      }
    } catch (err) {
      //console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden 
        //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }


  handleShowExpired() {
    this.setState({
      showExpired: true
    })
  }


  handleClose() {
    this.setState({
      message: {}
    });
  }

  handleInputChange(ev) {
    clearTimeout(this.state.searchTimeoutId)
  
    this.setState({
      searchTimeoutId: setTimeout(() => {
        this.performSearch()
      }, 600),
      keywords: ev.target.value
    });
  }

  performSearch() {
    const { keywords, searchStatus, searchResult, search } = this.state;
    if (keywords.length > 0) {
      const newResults = [];
      search.subscriptions.forEach(item => {
        // console.log(`planName: ${item.planName.toLowerCase()}, keywords: ${keywords.toLowerCase()}, status: ${searchStatus || 'All'}, check: ${item.planName.toLowerCase().includes(keywords.toLowerCase())}`);
          const keywordsCheck = (item.planName !== undefined && item.planName !== null && item.planName !== "" && item.planName.toLowerCase().includes(keywords.toLowerCase())) || item.productName.toLowerCase().includes(keywords.toLowerCase());
        searchStatus ? keywordsCheck && item.status == searchStatus && newResults.push(item)
          : keywordsCheck && newResults.push(item);
      })
      // console.log(newResults);
      this.setState({
        searchResult: {
          ...searchResult,
          subscriptions: newResults
        }
      })
    }
  }

  render() {
    const { t, classes } = this.props;
    const { message, keywords, searchStatus, searchResult, warning, valid, invalid, myTenants, types, token } = this.state;

    //console.log(gotExpiredItems,subscriptions);

    // page data
    const headerData = {
      header: t("subscription:corporate_subscriptions"),
      caption: t("subscription:corporate_subscriptions_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const featureData = [];

    const renderStatus = {
      PastDue: {
        text: t('subscription:past_due'),
      },
      Incomplete: {
        text: t('subscription:incomplete'),
      },
      Inactive: {
        text: t('subscription:inactive'),
      },
      Trialing: {
        text: t('subscription:trialing'),
      },
      Active: {
        text: t('subscription:active'),
      },
      NotStarted: {
        text: t('subscription:not_started'),
      },
      IncompleteExpired: {
        text: t('subscription:invalid'),
      },
      UnPaid: {
        text: t('subscription:unpaid'),
      },
      Canceled: {
        text: t('subscription:canceled'),
        color: "error"
      },
      Suspended: {
        text: t('subscription:suspended'),
        color: "error"
      },
      Unknown: {
        text: t('subscription:unknown'),
      }
    }

    const RenderSubscriptionSection = ({ dataSet, type, classes }) => {
      let title;
      switch (type) {
        case 'warning':
          title = t("subscription:subscription_reminder");
          break;
        case 'valid':
          title = t("subscription:valid") + ' ' + t("subscription:corporate_subscriptions");
          break;
        case 'invalid':
          title = t("subscription:invalid") + ' ' + t("subscription:corporate_subscriptions");
          break;
        default:
          title = t("subscription:search_result");
          break;
      }

      return <Box component="section" className={classes.sectionRoot}>
        <RenderSectionTitle data={title} />
        <Grid container className={classes.gridContainer}>
          {(!isEmpty(dataSet.subscriptions)) ?
            <Fragment>
              {dataSet.subscriptions.map((item, key) => <RenderSubscription key={key} item={item} isCorp />)}
              {dataSet.subsPaging.cursor != "" && (dataSet.subsPaging.cursor < dataSet.subsPaging.totalRecords) ?
                <Grid item xs={12} className={classes.item}>
                  <Box textAlign="center">
                    <IconLabelButton key={dataSet.subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={() => this.getMore(type, false)}>{t("common:show_more")}</IconLabelButton>
                  </Box>
                </Grid>
                : <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid>
              }
            </Fragment>
            : <Grid item xs={12} className={classes.item}>{t("subscription:no_subscription")}</Grid>
          }
        </Grid>
      </Box>
    }

    const RenderTenantLicenses = () => (
      <Box component="section" className={classes.sectionRoot}>
        <RenderSectionTitle data={t("subscription:tenant_licenses")} />
        <Grid container className={classes.gridContainer}>
          {(!isEmpty(myTenants.subscriptions)) ?
            <Fragment>
              {(!isEmpty(warning.subscriptions) || !isEmpty(valid.subscriptions) || !isEmpty(invalid.subscriptions)) ?
                myTenants.subscriptions.map((item, key) => <RenderTenantSm key={key} item={item} />)
                : myTenants.subscriptions.map((item, key) => <RenderTenant key={key} item={item} />)
              }
              {myTenants.subsPaging.cursor != "" && (myTenants.subsPaging.cursor < myTenants.subsPaging.totalRecords) ?
                <Grid item xs={12} className={classes.item}>
                  <Box textAlign="center">
                    <IconLabelButton key={myTenants.subscriptions.length} startIcon={<ArrowDownwardIcon />} onClick={() => this.getMore("myTenants", true)}>{t("common:show_more")}</IconLabelButton>
                  </Box>
                </Grid>
              : 
                <Grid item xs={12} className={classes.item}>{t("common:end")}</Grid>
              }
            </Fragment>
          : 
            <Grid item xs={12} className={classes.item}>{t("subscription:no_subscription")}</Grid>
          }
        </Grid>
      </Box>
    )

    return (
      <Container maxWidth="lg" className={classes.root}>
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />
        <RenderFeatureButton data={featureData} />

        {(!isEmpty(keywords) || !isEmpty(warning.subscriptions) || !isEmpty(valid.subscriptions) || !isEmpty(invalid.subscriptions)) ?
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              {!isEmpty(keywords) || !isEmpty(searchStatus) ?
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField value={keywords} onChange={this.handleInputChange} onKeyDown={this.handleSearch} id="keywords" name="keywords" label={t("subscription:search_box")} />
                    </Grid>
                    <Grid item xs={7} md={5}>
                      <Select value={searchStatus} onChange={this.handleInputChange} id="searchStatus" name="searchStatus" label={t("subscription:search_sataus_option")}>
                        <option value=""></option>
                        {searchResult.status.map(item => <option key={item} value={item}>{t(renderStatus[item].text)}</option>)}
                      </Select>
                    </Grid>
                  </Grid>

                  {/* Render Search Result */}
                  <RenderSubscriptionSection dataSet={searchResult} type='search' classes={classes} />
                </>
              : 
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField value={keywords} onChange={this.handleInputChange} id="keywords" name="keywords" label={t("subscription:search_box")} />
                    </Grid>
                    <Grid item xs={7} md={5}>
                      <Select value={searchStatus} onChange={this.handleInputChange} id="searchStatus" name="searchStatus" label={t("subscription:search_sataus_option")}>
                        <option value=""></option>
                        {searchResult.status.map(item => <option key={item} value={item}>{t(renderStatus[item].text)}</option>)}
                      </Select>
                    </Grid>
                  </Grid>

                  {/* Render Warning Subscription */}
                  <RenderSubscriptionSection dataSet={warning} type="warning" classes={classes} />

                  {/* Render Valid Subscription  */}
                  <RenderSubscriptionSection dataSet={valid} type="valid" classes={classes} />

                  {/* Render Invalid Subscription */}
                  <RenderSubscriptionSection dataSet={invalid} type="invalid" classes={classes} />
                </>
              }
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridBorder}>
              <RenderTenantLicenses />
            </Grid>
          </Grid>
        : 
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              <RenderTenantLicenses />
            </Grid>
          </Grid>
        }
      </Container>
    )
  }
}

export default withTranslation(["common", "subscription", "errors"])(withStyles(useStyles)(Corporate));