import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { tokenHeader} from "../../utils/Utils";
import { Container, Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { WorkEmail as useStyles } from "./Styles";
import apiRequest from "../../utils/apiRequest";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, Typography, TextField, ErrorMessage, Button } from "../UI/Core";

class WorkEmail extends Component {
  static displayName = WorkEmail.name;

  constructor(props) {
    super(props);
    //src=asianinvestor&continue=%2farticle%2farticle-lock-sub-only%2f376988
    this.timer = 0;
    this.state = {
      email: "",
      errors: {},
      disableSubmit: false,
      disableResend: false,
      returnurl: props.returnurl,
      workEmails: [],
      token: {},
      message: {},
      resend: false,
      counter: process.env.REACT_APP_WAITING,
      showCounter: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderResend = this.renderResend.bind(this);
    this.renderAddEmail = this.renderAddEmail.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  async componentDidMount() {
    const token = await tokenHeader();
    this.setState({ token: token });
  }
  componentWillUnmount(){
    clearInterval(this.timer);
  }
  handleInputChange = (ev) => {
    const name = ev.target.name;
    this.setState({
      ...this.state,
      [name]: ev.target.value
    });
  }

  startTimer() {
    if (this.timer===0 && this.state.counter > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.counter - 1;
    this.setState({ counter: seconds });
    if (seconds === 0) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        counter: process.env.REACT_APP_WAITING,
        showCounter: false,
        disableResend: false
      });
    }
  }

  // handleValidation() {
  //   const { email } = this.state;
  //   let errors = {};
  //   if (email === "") {
  //     errors["email"] = "errors:is_required";
  //   } else if (!emailCheck(email)) {
  //     errors["email"] = "errors:invalid";
  //   }

  //   if (Object.keys(errors).length) {
  //     this.setState({ errors: errors });
  //     return true;
  //   } else
  //     return false;
  // }

  handleSubmit = async () => {
    const {email, returnurl} = this.state;
    // if (!this.handleValidation()) {
      try {
        //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
        this.setState({
          disableSubmit: true, 
          errors: {},
          message: {}
        });
        //console.log(returnUrl);
        const res = await apiRequest.workemails.add({ email: email, returnurl: returnurl });
/*        const res = await axios.post('/api/v1/workemails',
          { email: email, returnUrl: returnUrl }, { headers: token });*/
        if (res.status === 200) {
          this.setState({
            disableSubmit: false,
            disableResend: true,
            showCounter: true,
            resend: true,
            message: { sentSuccessfully: true }
          });
          this.startTimer();
          //window.location.href = './manage/profile?message=checkMailbox'
        }
      } catch (err) {
        //console.log(err.response);
        this.setState({ disableSubmit: false });
        if (err.response.status === 400) {
          const { error } = err.response.data;
          let errors = {};
          if (error.params) {
            Object.keys(error.params).forEach(function (key) {
              errors[key] = "errors:" + error.params[key][0];
            });
            this.setState({
              resend: false,
              showCounter: false,
              errors: errors,
            });
          }
        } else if (err.response.status === 403) {
          //403: Forbidden 
          //404: No such user
          this.setState({
            disableResend: true,
            showCounter: false,
            message: { badRequestError: true }
          });
        } else if (err.response.status === 500) {
          this.setState({
            disableResend: false,
            showCounter: false,
            message: { internalServerError: true }
          });
        }
      }
    // }
  }

  handleCancel() {
    clearInterval(this.timer);
    this.props.onCloseEdit();
  }

  handleClose() {
    this.setState({ message: {} });
  };

  renderResend() {
    const { t, classes } = this.props;
    const { message, disableSubmit, disableResend, showCounter, counter, email } = this.state;

    // page data
    const headerData = {
      header: t("email_validation:email_validation")
    }

    const messageData = [
      {
        open: message.sentSuccessfully,
        onClose: this.handleClose,
        severity: "success",
        children: t("email_validation:email_sent_successful")
      },
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        text: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]
    const footerData = [
      <Button fullWidth disabled={(disableSubmit || disableResend)} onClick={this.handleSubmit}>{t("common:resend")} {showCounter && "(" + counter + ")"}</Button>
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs>
              <div>
                <Typography dangerouslySetInnerHTML={{ __html: t("email_validation:email_validation_content")}} variant="body1" component="span"/>
                <Typography variant="body1" component="span">{email}.</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: t("email_validation:email_validation_content_two")}} variant="body1" component="span"/>
              </div>
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} />
      </Container >
    )
  }

  renderAddEmail() {
    const { t, classes } = this.props;
    const { errors, message, disableSubmit, email } = this.state;
    //console.log(this.state.returnUrl);
    // page data
    const headerData = {
      header: t("profile:add_work_email"),
      caption: t("profile:add_work_email_caption")
    }

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        text: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      }
    ]

    const footerData = [
      <Button color="secondary" fullWidth onClick={this.handleCancel} disabled={disableSubmit}>{t("common:cancel")}</Button>,
      <Button fullWidth disabled={disableSubmit} onClick={this.handleSubmit}>{t("common:submit")}</Button>
    ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <Box component="section" className={classes.sectionRoot}>
          <Grid container className={classes.gridContainer}>
            {/* email */}
            <Grid item xs={12} md={6}>
              <TextField value={email} onChange={this.handleInputChange} id="email" name="email" label={t("profile:work_email") + " *"} error={Boolean(errors["email"])} disabled={disableSubmit} />
              {(errors["email"]) && <ErrorMessage>{t("profile:work_email")}{t(errors["email"])}</ErrorMessage>}
            </Grid>
          </Grid>
        </Box>

        <RenderFooter data={footerData} />
      </Container>
    )
  }

  render() {
    return (this.state.resend) ?this.renderResend():this.renderAddEmail();
  }
}

export default withTranslation(['common', 'profile', 'email_validation', 'errors'])(withStyles(useStyles)(WorkEmail));