import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  label: {
    flexGrow: 1
  }
}));

export default function (props) {
  if (props.fullwidth) {
    const classes = useStyles(props);
    return (
      <FormControlLabel
        control={<Checkbox color="primary" />}
        classes={{
            root: classes.root,
            label: classes.label
        }}
        labelPlacement={props.position}
        {...props}
      />
    )
  } else {
    return (
      <FormControlLabel
        control={<Checkbox color="primary" />}
        labelPlacement={props.position}
        {...props}
      />
    )
  }
}