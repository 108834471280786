import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

Typography.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}

const useStyles = makeStyles(theme => ({
  color: props => (
    props.color === "success" ||
    props.color === "info" ||
    props.color === "warning" ||
    props.color === "error"
  ) && ({
    color: theme.palette[props.color].main

  })
}));

export default function Typography(props) {
  const { className, color, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiTypography
      // gutterBottom
      color={((
        color === "initial" ||
        color === "inherit" ||
        color === "primary" ||
        color === "secondary" ||
        color === "textPrimary" ||
        color === "textSecondary"
      ) && color) || "textPrimary"
      }
      className={clsx(
        (
          color === "success" ||
          color === "info" ||
          color === "warning" ||
          color === "error"
        ) && classes.color,
        className
      )}
      {...rest}
    >{props.children}</MuiTypography>
  )
}
