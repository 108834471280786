import PropTypes from 'prop-types';

export default function ColumnGrid(props) {
  const { theme, argument, breakpoint, horiz } = props;
  const children = !horiz ? {
    width: "calc(100% + " + theme.spacing(argument) + "px)",
    margin: theme.spacing(-argument / 2),

    "& > *": {
      padding: theme.spacing(argument / 2)
    }
  } : {
      width: "calc(100% + " + theme.spacing(argument) + "px)",
      marginLeft: theme.spacing(-argument / 2),
      marginRight: theme.spacing(-argument / 2),

      "& > *": {
        paddingLeft: theme.spacing(argument / 2),
        paddingRight: theme.spacing(argument / 2)
      }
    }

  if (breakpoint) {
    return ({
      [theme.breakpoints.up(breakpoint)]: children
    })
  } else return children
}

ColumnGrid.propTypes = {
  theme: PropTypes.object.isRequired,
  argument: PropTypes.number.isRequired,
  breakpoint: PropTypes.string,
  horiz: PropTypes.bool
}