import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import Header from "./Header";
import Footer from "./Footer";

function Layout(props) {
  const { i18n } = props;

  return (
    <Box className={i18n.language}>
      <Header />
      {props.children}
      <Footer />
    </Box>
  )
}

export default withTranslation()(withRouter(Layout));