import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { GatewayContext } from "../../utils/GatewayContext";
import { isEmpty, updateAddress, updatePhonePrefix } from "../../utils/Utils";
import { Container, Box, Grid, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Address as useStyles } from "./Styles";
import { Backdrop, RenderHeader, RenderMessage, RenderFooter, RenderSectionTitle, TextField, Select, Checkbox, ErrorMessage, Button } from "../UI/Core";
import PhonePrefix from "../common/PhonePrefix";
import apiRequest from "../../utils/apiRequest";

class Address extends Component {
  static displayName = Address.name;
  constructor(props) {
    super(props);
    this.state = {
      userAddress: {
        "phone": {
          countryCode: "",//HK
          normalizedPhoneNumber: "", //+85264321325
          phoneNumber: "",//64321325
          prefix: 0, //852
        },
        "billingAddress": {
          "city": "",
          "countryCode": "",
          "line1": "",
          "line2": "",
          "postalCode": "",
          "state": ""
        },
        "shipToBillingAddress": false,
        "shippingAddress": {
          "name": "",
          "phone": {
            countryCode: "",//HK
            normalizedPhoneNumber: "", //+85264321325
            phoneNumber: "",//64321325
            prefix: 0, //852
          },
          "address": {
            "city": "",
            "countryCode": "",
            "line1": "",
            "line2": "",
            "postalCode": "",
            "state": ""
          }
        }
      },
      ownUpdate: false,
      errors: {},
      errorStr: "",
      message: {},
      disableSubmit: false,

    }

    this.handlePrevStep = this.handlePrevStep.bind(this);
    this.handleBillingInputChange = this.handleBillingInputChange.bind(this);
    this.handleShippingInputChange = this.handleShippingInputChange.bind(this);
    // this.handleValidation = this.handleValidation.bind(this);
    this.updatePhonePrefixHandler = this.updatePhonePrefixHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseMessage = this.handleCloseMessage.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderBillingAddress = this.renderBillingAddress.bind(this);
    this.renderShippingAddress = this.renderShippingAddress.bind(this);
  }

  async componentDidMount() {
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userAddress && props.userAddress !== state.userAddress && !state.ownUpdate) {
      //console.log(props.userAddress);

      let phone, billingAddress, shippingAddress;

      if (props.userAddress.phone && !isEmpty(props.userAddress.phone))
        phone = { ...state.userAddress.phone, ...props.userAddress.phone }
      else
        phone = { ...state.userAddress.phone }

      if (props.userAddress.billingAddress && !isEmpty(props.userAddress.billingAddress))
        billingAddress = { ...state.userAddress.billingAddress, ...props.userAddress.billingAddress }
      else
        billingAddress = { ...state.userAddress.billingAddress }

      if (props.userAddress.shippingAddress && !isEmpty(props.userAddress.shippingAddress))
        shippingAddress = { ...state.userAddress.shippingAddress, ...props.userAddress.shippingAddress }
      else
        shippingAddress = { ...state.userAddress.shippingAddress }



      return {
        userAddress: {
          "phone": phone,
          "billingAddress": billingAddress,
          "shipToBillingAddress": false,
          "shippingAddress": shippingAddress
        }
      }
    }
    return null;
  }

  updatePhonePrefixHandler(data) {
    let newData = updatePhonePrefix(this.state.userAddress, data);
    this.setState(newData);
  }

  handleBillingInputChange(ev) {
    const { userAddress } = this.state;
    let newData = updateAddress(userAddress, this.props.countries, 'billing', { key: ev.target.name, value: ev.target.value });
    //console.log(newData);
    this.setState(newData);
  }

  handleShippingInputChange(ev) {
    const { userAddress } = this.state;
    let newData = updateAddress(userAddress, this.props.countries, 'shipping', { key: ev.target.name, value: ev.target.value });

    this.setState(newData);
  }


  async handleSubmit() {
    const { userAddress } = this.state;
    let res, _this = this;
    // if (!this.handleValidation()) {
    this.setState({
      disableSubmit: true,
      errors: {},
      message: {}
    });
    try {
      res = await apiRequest.address.update(userAddress);
      if (res.status === 200) {
        if (this.props.fromDashboard)
          this.setState({ message: { addressesUpdated: true } });
        else
          this.props.nextStepHandler({ step: this.props.step, addresses: { ...userAddress, ...res.data.result } });
      }
    } catch (err) {
      this.setState({ disableSubmit: false });
      if (err.response.status === 400) {
        const { error } = err.response.data;
        let errors = {};
        if (error.params) {
          Object.keys(error.params).forEach(function (key) {
            errors[key] = "errors:" + error.params[key][0];
          });
          _this.setState({
            errors: errors
          })
        }
      } else if (err.response.status === 403 || err.response.status === 404) {
        console.log(err.response.data.error.message);
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
      //401 Unauthorized
      //403 Forbidden
      //404 No such user
      //500
    }
    // }
  }

  handlePrevStep(e) {
    this.props.prevStepHandler({ step: this.props.step });
  }

  handleCloseMessage() {
    this.setState({
      message: {}
    });
  }
  handleCancel() {
    this.props.onCloseEdit();
  }

  renderBillingAddress() {
    const { t, classes } = this.props;
    const { errors, userAddress, disableSubmit } = this.state;
    const { line1, line2, city, countryCode, postalCode, state } = userAddress.billingAddress;
    let { phone } = userAddress;
    //phone = removePhonePrefix(phone);

    //console.log(userAddress);

    return (
      <Box component="section" className={classes.sectionRoot}>
        <RenderSectionTitle gutters data={t("address:billing_address")} />

        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <TextField value={line1} onChange={this.handleBillingInputChange} name="line1" type="text" label={t("address:address_line_1") + " *"} error={Boolean(errors["billingAddress.Line1"])} disabled={disableSubmit} />
            {(errors["billingAddress.Line1"]) && <ErrorMessage>{t("address:address_line_1")}{t(errors["billingAddress.Line1"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12}>
            <TextField value={line2} onChange={this.handleBillingInputChange} name="line2" type="text" label={t("address:address_line_2")} error={Boolean(errors["billingAddress.Line2"])} disabled={disableSubmit} />
            {(errors["billingAddress.Line2"]) && <ErrorMessage>{t("address:address_line_2")}{t(errors["billingAddress.Line2"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={city} onChange={this.handleBillingInputChange} name="city" type="text" label={t("address:city") + " *"} error={Boolean(errors["billingAddress.City"])} disabled={disableSubmit} />
            {(errors["billingAddress.City"]) && <ErrorMessage>{t("address:city")}{t(errors["billingAddress.City"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={state} onChange={this.handleBillingInputChange} name="state" type="text" label={t("address:state")} error={Boolean(errors["billingAddress.State"])} disabled={disableSubmit} />
            {(errors["billingAddress.State"]) && <ErrorMessage>{t("address:state")}{t(errors["billingAddress.State"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={postalCode} InputLabelProps={{ shrink: true }}   InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={this.handleBillingInputChange} name="postalCode" type="number" label={t("address:postal_code")} error={Boolean(errors["billingAddress.PostalCode"])} disabled={disableSubmit} />
            {(errors["billingAddress.PostalCode"]) && <ErrorMessage>{t("address:postal_code")}{t(errors["billingAddress.PostalCode"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <Select value={countryCode} onChange={this.handleBillingInputChange} name="countryCode" label={t("address:region") + " *"} error={Boolean(errors["billingAddress.CountryCode"])} disabled={disableSubmit}>
              <GatewayContext.Consumer>
                {context =>
                  context.countries && Object.keys(context.countries).map(key =>
                    <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                  )
                }
              </GatewayContext.Consumer>
            </Select>
            {(errors["billingAddress.CountryCode"]) && <ErrorMessage>{t("address:region")}{t(errors["billingAddress.CountryCode"])}</ErrorMessage>}
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={5} md={3}>
            <Select value={phone.countryCode} onChange={this.handleBillingInputChange} name="phoneCode" label={t("common:country_code") + " *"} error={Boolean(errors["billingAddress.CountryCode"])} disabled={disableSubmit}>
              <GatewayContext.Consumer>
                {context =>
                  context.countries && Object.keys(context.countries).map(key =>
                    <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                  )
                }
              </GatewayContext.Consumer>
            </Select>
          </Grid>

          <Grid item xs={12} sm={7} md={6}>
            <TextField value={phone.phoneNumber} onChange={this.handleBillingInputChange} name="phone" type="tel" label={t("address:phone") + " *"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GatewayContext.Consumer>
                      {context =>
                        <PhonePrefix onPrefixChange={this.updatePhonePrefixHandler} countries={context.countries} name={"billingPhonePrefix"} selected={phone.countryCode} />
                      }
                    </GatewayContext.Consumer>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors["phone.CountryCode"] || errors["phone.Prefix"] || errors["phone.PhoneNumber"] || errors["phone.NormalizedPhoneNumber"])} disabled={disableSubmit}
            />
            {(errors["phone.Prefix"] || errors["phone.PhoneNumber"] || errors["phone.NormalizedPhoneNumber"]) &&
              <ErrorMessage>
                {errors["phone.Prefix"] && t("address:region") + t(errors["phone.Prefix"])}
                {errors["phone.PhoneNumber"] && (errors["phone.Prefix"] ? t("common:full_stop") : "") + t("address:phone_number") + t(errors["phone.PhoneNumber"])}
                {errors["phone.NormalizedPhoneNumber"] && (errors["phone.Prefix"] || errors["phone.PhoneNumber"] ? t("common:full_stop") : "") + t("address:phone_number") + t(errors["phone.NormalizedPhoneNumber"])}
              </ErrorMessage>
            }
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderShippingAddress() {
    const { t, classes } = this.props;
    const { errors, userAddress, disableSubmit } = this.state;
    const { shipToBillingAddress, shippingAddress } = userAddress;
    const { name, address } = shippingAddress;
    const { line1, line2, city, countryCode, postalCode, state } = address;
    let { phone } = shippingAddress;
    //phone = removePhonePrefix(phone);

    //console.log(postalCode);
    return (
      <Box component="section" className={classes.sectionRoot}>
        <RenderSectionTitle gutters data={t("address:shipping_address")} />

        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <TextField value={name} onChange={this.handleShippingInputChange} name="name" type="text" label={t("address:name") + " *"} error={Boolean(errors["shippingAddress.Name"])} disabled={disableSubmit} />
            {(errors["shippingAddress.Name"]) && <ErrorMessage>{t("address:name")}{t(errors["shippingAddress.Name"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12}>
            <Checkbox checked={shipToBillingAddress} onChange={this.handleShippingInputChange} name="shipToBillingAddress" label={t("address:same_as_billing_address")} disabled={disableSubmit} />
          </Grid>

          <Grid item xs={12}>
            <TextField value={line1} onChange={this.handleShippingInputChange} name="line1" type="text" label={t("address:address_line_1") + " *"} error={Boolean(errors["shippingAddress.Address.Line1"])} disabled={(disableSubmit || shipToBillingAddress)} />
            {(errors["shippingAddress.Address.Line1"]) && <ErrorMessage>{t("address:address_line_1")}{t(errors["shippingAddress.Address.Line1"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12}>
            <TextField value={line2} onChange={this.handleShippingInputChange} name="line2" type="text" label={t("address:address_line_2")} error={Boolean(errors["shippingAddress.Address.Line2"])} disabled={(disableSubmit || shipToBillingAddress)} />
            {(errors["shippingAddress.Address.Line2"]) && <ErrorMessage>{t("address:address_line_2")}{t(errors["shippingAddress.Address.Line2"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={city} onChange={this.handleShippingInputChange} name="city" type="text" label={t("address:city") + " *"} error={Boolean(errors["shippingAddress.Address.City"])} disabled={(disableSubmit || shipToBillingAddress)} />
            {(errors["shippingAddress.Address.City"]) && <ErrorMessage>{t("address:city")}{t(errors["shippingAddress.Address.City"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={state} onChange={this.handleShippingInputChange} name="state" type="text" label={t("address:state")} error={Boolean(errors["shippingAddress.Address.State"])} disabled={(disableSubmit || shipToBillingAddress)} />
            {(errors["shippingAddress.Address.State"]) && <ErrorMessage>{t("address:state")}{t(errors["shippingAddress.Address.State"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField value={postalCode} InputLabelProps={{ shrink: true }}   InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={this.handleShippingInputChange} name="postalCode" type="number" label={t("address:postal_code")} error={Boolean(errors["shippingAddress.Address.PostalCode"])} disabled={(disableSubmit || shipToBillingAddress)} />
            {(errors["shippingAddress.Address.PostalCode"]) && <ErrorMessage>{t("address:postal_code")}{t(errors["shippingAddress.Address.PostalCode"])}</ErrorMessage>}
          </Grid>

          <Grid item xs={12} md={6}>
            <Select value={countryCode} onChange={this.handleShippingInputChange} name="countryCode" label={t("address:region") + " *"} error={Boolean(errors["shippingAddress.Address.CountryCode"])} disabled={(disableSubmit || shipToBillingAddress)}>
              <GatewayContext.Consumer>
                {context =>
                  context.countries && Object.keys(context.countries).map(key =>
                    <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                  )
                }
              </GatewayContext.Consumer>
            </Select>
            {(errors["shippingAddress.Address.CountryCode"]) && <ErrorMessage>{t("address:region")}{t(errors["shippingAddress.Address.CountryCode"])}</ErrorMessage>}
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={5} md={3}>
            <Select value={phone.countryCode} onChange={this.handleShippingInputChange} name="phoneCode" label={t("common:country_code") + " *"} error={Boolean(errors["shipping.CountryCode"])} disabled={(disableSubmit || shipToBillingAddress)}>
              <GatewayContext.Consumer>
                {context =>
                  context.countries && Object.keys(context.countries).map(key =>
                    <option key={context.countries[key].code} value={context.countries[key].code}>{t("regions:" + context.countries[key].code)}</option>
                  )
                }
              </GatewayContext.Consumer>
            </Select>
          </Grid>

          <Grid item xs={12} sm={7} md={6}>
            <TextField value={phone.phoneNumber} onChange={this.handleShippingInputChange} name="phone" type="tel" label={t("address:phone") + " *"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GatewayContext.Consumer>
                      {context => <PhonePrefix onPrefixChange={this.updatePhonePrefixHandler} countries={context.countries} name={"shippingPhonePrefix"} selected={phone.countryCode} />
                      }
                    </GatewayContext.Consumer>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors["shippingAddress.Phone.CountryCode"] || errors["shippingAddress.Phone.Prefix"] || errors["shippingAddress.Phone.PhoneNumber"] || errors["shippingAddress.Phone.NormalizedPhoneNumber"])} disabled={(disableSubmit || shipToBillingAddress)}
            />
            {(errors["shippingAddress.Phone.Prefix"] || errors["shippingAddress.Phone.PhoneNumber"] || errors["shippingAddress.Phone.NormalizedPhoneNumber"]) &&
              <ErrorMessage>
                {errors["shippingAddress.Phone.Prefix"] && t("address:region") + t(errors["shippingAddress.Phone.Prefix"])}
                {errors["shippingAddress.Phone.PhoneNumber"] && (errors["shippingAddress.Phone.Prefix"] ? t("common:full_stop") : "") + t("address:phone_number") + t(errors["shippingAddress.Phone.PhoneNumber"])}
                {errors["shippingAddress.Phone.NormalizedPhoneNumber"] && (errors["shippingAddress.Phone.Prefix"] || errors["shippingAddress.Phone.PhoneNumber"] ? t("common:full_stop") : "") + t("address:phone_number") + t(errors["shippingAddress.Phone.NormalizedPhoneNumber"])}
              </ErrorMessage>
            }
          </Grid>
        </Grid>
      </Box>
    )
  }

  render() {
    const { t, fromDashboard } = this.props;
    const { message, disableSubmit } = this.state;
    const { classes } = this.props;
    const RenderBillingAddress = this.renderBillingAddress;
    const RenderShippingAddress = this.renderShippingAddress;

    //console.log(this.state);


    // page data
    const headerData = (!fromDashboard) ? {
      header: t("address:subscription_billing_and_shipping"),
      caption: t("address:subscription_billing_and_shipping_caption")
    } : {
        header: t("address:edit_billing_and_shipping"),
        caption: t("address:edit_billing_and_shipping_caption")
      };

    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleCloseMessage,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleCloseMessage,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.addressesUpdated,
        onClose: this.handleCancel,
        severity: "success",
        children: t("address:address_is_updated")
      }
    ]

    const footerData = (!fromDashboard) ? [
      <Button color="secondary" onClick={this.handlePrevStep} fullWidth disabled={disableSubmit}>{t("common:back")}</Button>,
      <Button onClick={this.handleSubmit} fullWidth disabled={disableSubmit}>{t("common:next")}</Button>
    ] : [
        <Button color="secondary" onClick={this.handleCancel} fullWidth disabled={disableSubmit}>{t("common:cancel")}</Button>,
        <Button onClick={this.handleSubmit} fullWidth disabled={disableSubmit}>{t("common:save")}</Button>
      ]

    return (
      <Container maxWidth="md" className={classes.root}>
        <Backdrop variant="processing" open={disableSubmit} />
        <RenderHeader {...headerData} />
        <RenderMessage data={messageData} />

        <RenderBillingAddress />
        <RenderShippingAddress />

        <RenderFooter data={footerData} />
      </Container>
    )
  }
}


export default withTranslation(["common", "errors", "address", "regions"])(withStyles(useStyles)(Address));
