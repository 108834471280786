import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

IconLabelButton.propTypes = {
  gutters: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "third", "success", "info", "warning", "error"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  className: PropTypes.string
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
  },
  gutters: {
    margin: theme.spacing(2, 0, 1)
  },
  color: props => (
    props.color === "success" ||
    props.color === "info" ||
    props.color === "warning" ||
    props.color === "error"
  ) && ({
    backgroundColor: theme.palette[props.color].main,
    "&:hover": {
      backgroundColor: theme.palette[props.color].dark
    }
  }),
  text: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.dark
    }
  }
}));

export default function IconLabelButton(props) {
  const { gutters, color, size, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Button
      color={color === "third" ? "secondary" : "primary"}
      variant={color === "secondary" ? "outlined" : "contained"}
      size={((
          size === "small" ||
          size === "medium" ||
          size === "large"
        ) && size ) || "medium"
      }
      // disableElevation
      className={clsx(
        classes.root,
        
        (
          color === "success" ||
          color === "info" ||
          color === "warning" ||
          color === "error"
        ) && classes.color,
        props.variant === "text" && classes.text,
        gutters && classes.gutters,
        className)}
      {...rest}
    >{props.children}</Button>
  )
}
